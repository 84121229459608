// import PropTypes from 'prop-types';
// // form
// import { useFormContext, Controller } from 'react-hook-form';
// // @mui
// import { TextField } from '@mui/material';

// // ----------------------------------------------------------------------

// RHFSelect.propTypes = {
//     children: PropTypes.node,
//     name: PropTypes.string,
// };

// export default function RHFSelect({ name, children, ...other }) {
//     const { control } = useFormContext();

//     return (
//         <Controller
//             name={name}
//             control={control}
//             render={({ field, fieldState: { error } }) => (
//                 <TextField
//                     {...field}
//                     select
//                     fullWidth
//                     SelectProps={{ native: true }}
//                     error={!!error}
//                     helperText={error?.message}
//                     {...other}
//                 >
//                     {children}
//                 </TextField>
//             )}
//         />
//     );
// }
import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFSelect.propTypes = {
    children: PropTypes.node,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};

export default function RHFSelect({ name, children, onChange, ...other }) {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <TextField
                    {...field}
                    select
                    fullWidth
                    SelectProps={{ native: true }}
                    error={!!error}
                    helperText={error?.message}
                    onChange={(e) => {
                        console.log('Field onChange called');
                        field.onChange(e);
                        if (onChange) {
                            console.log('Prop onChange called');
                            onChange(e);
                        }
                    }}
                    {...other}
                >
                    {children}
                </TextField>
            )}
        />
    );
}

