// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    register: path(ROOTS_AUTH, '/register'),
    loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
    registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
    verify: path(ROOTS_AUTH, '/verify'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
    newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
    home: '/',
    comingSoon: '/coming-soon',
    maintenance: '/maintenance',
    about: '/about-us',
    contact: 'contact',
    page403: '/403',
    page404: '/404',
    page500: '/500',
    components: '/components',
};

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    general: {
        app: path(ROOTS_DASHBOARD, '/app'),
    },
    permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
    tenantadmin: {
        root: path(ROOTS_DASHBOARD, '/tenantadmin'),
        account: path(ROOTS_DASHBOARD, '/tenantadmin/account'),
        nursemanager: path(ROOTS_DASHBOARD, '/tenantadmin/nursemanager'),
        nurse: path(ROOTS_DASHBOARD, '/tenantadmin/nurse'),
        medicalassistant: path(ROOTS_DASHBOARD, '/tenantadmin/medicalassistant'),
        patient: path(ROOTS_DASHBOARD, '/tenantadmin/patient'),
    },
    organization: {
        root: path(ROOTS_DASHBOARD, '/organization'),
        manage: path(ROOTS_DASHBOARD, '/organization/manage'),
    },
    patient: {
        root: path(ROOTS_DASHBOARD, '/patient'),
        list: path(ROOTS_DASHBOARD, '/patient/list'),
        new: id => path(ROOTS_DASHBOARD, `/patient/${id}/new`),
        edit: id => path(ROOTS_DASHBOARD, `/patient/${id}/edit`),
        view: id => path(ROOTS_DASHBOARD, `/patient/${id}/view`),
        ccmnew: id => path(ROOTS_DASHBOARD, `/patient/${id}/ccmnew`),
        ccmedit: id => path(ROOTS_DASHBOARD, `/patient/${id}/ccmedit`),
        readings: path(ROOTS_DASHBOARD, '/patient/readings'),
        misseduploads: path(ROOTS_DASHBOARD, '/patient/misseduploads'),
        servicetime: path(ROOTS_DASHBOARD, '/patient/servicetime'),
        //revenue: path(ROOTS_DASHBOARD, '/patient/revenue'),
        //onboardingreport: path(ROOTS_DASHBOARD, '/patient/onboardingreport'),
    },
    reports: {
        // root: path(ROOTS_DASHBOARD, '/patient'),
        // list: path(ROOTS_DASHBOARD, '/patient/list'),
        // new: id => path(ROOTS_DASHBOARD, `/patient/${id}/new`),
        // edit: id => path(ROOTS_DASHBOARD, `/patient/${id}/edit`),
        // view: id => path(ROOTS_DASHBOARD, `/patient/${id}/view`),
        // ccmnew: id => path(ROOTS_DASHBOARD, `/patient/${id}/ccmnew`),
        // ccmedit: id => path(ROOTS_DASHBOARD, `/patient/${id}/ccmedit`),
        // readings: path(ROOTS_DASHBOARD, '/patient/readings'),
        // misseduploads: path(ROOTS_DASHBOARD, '/patient/misseduploads'),
        // servicetime: path(ROOTS_DASHBOARD, '/patient/servicetime'),
        revenue: path(ROOTS_DASHBOARD, '/reports/revenue'),
        onboardingreport: path(ROOTS_DASHBOARD, '/reports/onboardingreport'),
        lastccmcall: path(ROOTS_DASHBOARD, '/reports/lastccmcall'),
        qualitymeasurereport: path(ROOTS_DASHBOARD, '/reports/qualitymeasurereport')
    },
    device: {
        root: path(ROOTS_DASHBOARD, '/device'),
        manage: path(ROOTS_DASHBOARD, '/device/manage'),
        tracking: path(ROOTS_DASHBOARD, '/device/tracking'),
        add: path(ROOTS_DASHBOARD, '/device/add'),
        orderedDevice: path(ROOTS_DASHBOARD, '/device/ordered-device'),
    },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
