// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import MonitorTwoToneIcon from '@mui/icons-material/MonitorTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import TaskTwoToneIcon from '@mui/icons-material/TaskTwoTone';
import DashboardIcon from '@mui/icons-material/Dashboard';
// ----------------------------------------------------------------------

const getIcon = name => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
    blog: getIcon('ic_blog'),
    cart: getIcon('ic_cart'),
    chat: getIcon('ic_chat'),
    mail: getIcon('ic_mail'),
    user: getIcon('ic_user'),
    kanban: getIcon('ic_kanban'),
    banking: getIcon('ic_banking'),
    booking: getIcon('ic_booking'),
    invoice: getIcon('ic_invoice'),
    calendar: getIcon('ic_calendar'),
    ecommerce: getIcon('ic_ecommerce'),
    analytics: getIcon('ic_analytics'),
    dashboard: getIcon('ic_dashboard'),
    menuItem: getIcon('ic_menu_item'),
};

const navConfig = [
    // GENERAL
    // ----------------------------------------------------------------------
    // {
    //     subheader: 'general',
    //     items: [{ title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard }],
    // },

    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
        subheader: 'manage',
        items: [

            {
                title: 'Tasks',
                path: PATH_DASHBOARD.general.app,
                icon: <TaskTwoToneIcon />,
            },
            // {
            //     title: 'Organization',
            //     path: PATH_DASHBOARD.organization.manage,
            //     icon: <AccountTreeTwoToneIcon />,
            //     roles: ['tenantadmin'],
            // },
            {
                title: 'patient',
                path: PATH_DASHBOARD.patient.root,
                icon: <SupervisedUserCircleIcon />,
                roles: ['tenantadmin', 'medicalassistant', 'nursemanager', 'nurse'],
                children: [
                    { title: 'profiles', path: PATH_DASHBOARD.patient.list },
                    { title: 'readings', path: PATH_DASHBOARD.patient.readings },
                    { title: 'missed uploads', path: PATH_DASHBOARD.patient.misseduploads },
                    { title: 'service times', path: PATH_DASHBOARD.patient.servicetime },
                    //{ title: 'Revenue', path: PATH_DASHBOARD.patient.revenue },
                    //{ title: 'Onboarding Report', path: PATH_DASHBOARD.patient.onboardingreport },
                ],
            },
            {
                title: 'reports',
                path: PATH_DASHBOARD.reports.root,
                icon: <DashboardIcon />,
                roles: ['tenantadmin', 'medicalassistant', 'nursemanager', 'nurse'],
                children: [
                    // { title: 'profiles', path: PATH_DASHBOARD.patient.list },
                    // { title: 'readings', path: PATH_DASHBOARD.patient.readings },
                    // { title: 'missed uploads', path: PATH_DASHBOARD.patient.misseduploads },
                    // { title: 'service times', path: PATH_DASHBOARD.patient.servicetime },
                    // { title: 'Revenue', path: PATH_DASHBOARD.patient.revenue },
                    { title: 'Revenue', path: PATH_DASHBOARD.reports.revenue },
                    { title: 'Onboarding Report', path: PATH_DASHBOARD.reports.onboardingreport },
                    { title: 'CCM Call Report', path: PATH_DASHBOARD.reports.lastccmcall },
                    {title : "Quality Measure Dashboard", path: PATH_DASHBOARD.reports.qualitymeasurereport}
                ],
            },
            {
                title: 'device',
                path: PATH_DASHBOARD.device.manage,
                icon: <MonitorTwoToneIcon />,
                roles: ['medicalassistant'],
                // children: [
                //     { title: 'manage', path: PATH_DASHBOARD.device.manage },
                // ],
                children: [
                    { title: 'manage Device', path: PATH_DASHBOARD.device.manage },
                    { title: 'Ordered Devices', path: PATH_DASHBOARD.device.orderedDevice },
                    { title: 'Order Now', path: PATH_DASHBOARD.device.add },
                ],
            },
        ],
    },
];

export default navConfig;
