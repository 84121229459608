import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Button, ButtonGroup, Tooltip } from '@mui/material';
import Iconify from 'src/components/Iconify';

import BlockContent from './BlockContent';
import RejectionFiles from './RejectionFiles';
import MultiFilePreview from './MultiFilePreview';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
    outline: 'none',
    padding: theme.spacing(5, 1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.neutral,
    border: `1px dashed ${theme.palette.grey[500_32]}`,
    '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

// ----------------------------------------------------------------------

UploadMultiFile.propTypes = {
    files: PropTypes.array.isRequired,
    error: PropTypes.bool,
    showPreview: PropTypes.bool,
    onUpload: PropTypes.func,
    onRemove: PropTypes.func,
    onRemoveAll: PropTypes.func,
    helperText: PropTypes.node,
    sx: PropTypes.object,
};

export default function UploadMultiFile({
    error,
    showPreview = false,
    files,
    onUpload,
    onRemove,
    onCrop,
    onRemoveAll,
    onResetSizeAll,
    onSelect,
    helperText,
    sx,
    ...other
}) {
    const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
        ...other,
    });

    return (
        <Box sx={{ width: '100%', ...sx }}>
            <DropZoneStyle
                {...getRootProps()}
                sx={{
                    ...(isDragActive && { opacity: 0.72 }),
                    ...((isDragReject || error) && {
                        color: 'error.main',
                        borderColor: 'error.light',
                        bgcolor: 'error.lighter',
                    }),
                }}
            >
                <input {...getInputProps()} />

                <BlockContent />
            </DropZoneStyle>

            {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

            {files.length > 0 && (
                <Stack direction="row" justifyContent="center" alignItems="flex-start" spacing={4} sx={{ m: 2 }}>
                    <MultiFilePreview
                        files={files}
                        showPreview={showPreview}
                        onRemove={onRemove}
                        onCrop={onCrop}
                        onSelect={onSelect}
                    />
                    <ButtonGroup>
                        <Tooltip title="Rest to original sizes">
                            <Button
                                startIcon={<Iconify icon={'eva:refresh-fill'} />}
                                size="small"
                                onClick={onResetSizeAll}
                            />
                        </Tooltip>
                        <Tooltip title="Delete all">
                            <Button startIcon={<Iconify icon={'ep:delete'} />} size="small" onClick={onRemoveAll} />
                        </Tooltip>
                    </ButtonGroup>
                </Stack>
            )}

            {/* {files.length > 0 && (
                <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
                    <Button color="inherit" size="small" onClick={onRemoveAll}>
                        Remove all
                    </Button>
                    <Button size="small" variant="contained" onClick={onUpload}>
                        Upload files
                    </Button>
                </Stack>
            )} */}

            {helperText && helperText}
        </Box>
    );
}
