import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { getTenantAssignedDevices } from './libs/api';
import useAuth from 'src/hooks/useAuth';

// routes
import Router from './routes';

// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';

//Style
import './App.css';
// import { zoomAuthrequest } from 'src/libs/zoomauth';

// ----------------------------------------------------------------------
const onAddedObservation = `
    subscription AddedObservation($deviceID: String, $serialID: String) {
        addedObservation(deviceID: $deviceID, serialID: $serialID) {
            deviceID
            serialID
        }
    }
`;

export default function App() {
    const { user } = useAuth();
    const [notification, setNotification] = useState([]);
    const [subscription, setSubscription] = useState(false);

    // const subscribeDevices = async () => {
    //     if (user && user['custom:tenantID']) {
    //         const devices = await getTenantAssignedDevices(user['custom:tenantID']);
    //         // console.log('App.js: devices = ', devices);

    //         for (let device of devices) {
    //             const variables = {
    //                 deviceID: device.PK.split('#')[3],
    //                 serialID: device.SK.split('#')[1],
    //             };
    //             // console.log('variables = ', variables);
    //             const subscription = await API.graphql(graphqlOperation(onAddedObservation, variables)).subscribe({
    //                 next: ({ value }) => {
    //                     // console.log('SUBSCRIPTION');
    //                     // console.log({ value });
    //                     // console.log('value.data.addedObservation = ', value.data.addedObservation);
    //                     setNotification(notification => [value.data.addedObservation, ...notification]);
    //                     setSubscription(subscription);
    //                 },
    //                 error: error => console.warn(error),
    //             });
    //             // console.log('subscription: ', subscription);
    //         }
    //     }
    // };

    useEffect(() => {
        // zoomAuthrequest();
        // subscribeDevices();
        // return () => {
        //     subscription?.unsubscribe()
        // };
    }, [user]);

    return (
        <MotionLazyContainer>
            <ThemeProvider>
                <ThemeSettings>
                    <NotistackProvider>
                        <ProgressBarStyle />
                        <ScrollToTop />
                        <Router />
                    </NotistackProvider>
                </ThemeSettings>
            </ThemeProvider>
        </MotionLazyContainer>
    );
}
