import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Grid, Link, Container, Typography, Stack } from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
// components
import Image from '../../components/Image';

import SocialsButton from '../../components/SocialsButton';
import companyLogo from '../../assets/company/ConciergeRPMlogoBW.png';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(4, 0),
}));

const LINKS = [
    // {
    //     headline: 'Company',
    //     children: [{ name: 'About us', href: PATH_PAGE.about, type: 'Link' }],
    // },
    {
        headline: 'Solutions',
        children: [{ name: 'Request Information', href: 'info@conciergerpm.com', type: 'mail' }],
    },
    {
        headline: 'Contact',
        children: [
            { name: 'info@conciergerpm.com', href: 'info@conciergerpm.com', type: 'mail' },
            { name: 'p: (805) 864-3942', href: '8058643942', type: 'phone' },
            { name: 'f: (805) 855-4870', href: '#', type: 'none' },
        ],
    },
];

const Mailto = ({ email, subject, body, ...props }) => {
    return (
        <a href={`mailto:${email}?subject=${subject || ''}&body=${body || ''}`} style={{ textDecoration: 'none' }}>
            {props.children}
        </a>
    );
};

const Phoneto = ({ phoneNumber, ...props }) => {
    return (
        <a href={`tel:${phoneNumber}`} style={{ textDecoration: 'none' }}>
            {props.children}
        </a>
    );
};

// ----------------------------------------------------------------------

export default function MainFooter() {
    return (
        <RootStyle>
            <Container>
                <Grid
                    container
                    justifyContent={{ xs: 'center', md: 'space-around' }}
                    sx={{ textAlign: { xs: 'center', md: 'left' } }}
                >
                    <Grid item xs={6} md={4}>
                        {/* <Stack spacing={1} direction="column" justifyContent="flex-start" alignItems={'flex-start'}> */}

                        <Image disabledEffect alt="screen" src={companyLogo} sx={{ width: 160, height: 80, mb: 2 }} />
                        {/* <a href="tel:8058643942" style={{textDecoration: 'none'}}>
                                <Typography variant="body2" sx={{ pr: { md: 5 }, color: 'common.white' }}>
                                p: (805) 864-3942
                                </Typography>
                            </a>
                            <Typography variant="body2" sx={{ pr: { md: 2 }, color: 'common.white' }}>
                                f: (805) 855-4870
                            </Typography> */}
                        {/* </Stack> */}
                    </Grid>

                    {/* <Grid item xs={8} md={3}>

                    </Grid> */}

                    <Grid item xs={6} md={8}>
                        <Stack
                            spacing={{ xs: 2, md: 10 }}
                            direction={{ xs: 'column', md: 'row' }}
                            justifyContent="flex-end"
                        >
                            {LINKS.map(list => (
                                <Stack key={list.headline} spacing={1}>
                                    <Typography variant="overline" sx={{ color: 'white' }}>
                                        {list.headline}
                                    </Typography>

                                    {list.children.map(link => (
                                        <div key={link.name}>
                                            {link.type === 'Link' && (
                                                <Link
                                                    to={link.href}
                                                    key={link.name}
                                                    color="primary"
                                                    variant="body2"
                                                    component={RouterLink}
                                                    sx={{ display: 'block', color: 'white' }}
                                                >
                                                    <Typography variant="subtitle2" sx={{ color: 'common.white' }}>
                                                        {link.name}
                                                    </Typography>
                                                </Link>
                                            )}
                                            {link.type === 'mail' && (
                                                <Mailto email={link.href}>
                                                    <Typography variant="subtitle2" sx={{ color: 'common.white' }}>
                                                        {link.name}
                                                    </Typography>
                                                </Mailto>
                                            )}
                                            {link.type === 'phone' && (
                                                <Phoneto phoneNumber={link.href}>
                                                    <Typography variant="subtitle2" sx={{ color: 'common.white' }}>
                                                        {link.name}
                                                    </Typography>
                                                </Phoneto>
                                            )}
                                            {link.type === 'none' && (
                                                <Typography variant="subtitle2" sx={{ color: 'common.white' }}>
                                                    {link.name}
                                                </Typography>
                                            )}
                                        </div>
                                    ))}
                                </Stack>
                            ))}
                        </Stack>
                    </Grid>
                </Grid>

                <Typography
                    variant="body2"
                    sx={{
                        mt: 4,
                        pb: 1,
                        color: 'secondary.light',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    Copyright © 2022 Concierge RPM.
                    <Typography component="span" variant="body2" sx={{ color: 'common.white' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;All rights reserved
                    </Typography>
                </Typography>
            </Container>
        </RootStyle>
    );
}
