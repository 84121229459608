// i18n
import './locales/i18n';

// highlight
import './utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
import 'react-image-lightbox/style.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
// @mui
import { store, persistor } from './redux/store';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

import { Amplify } from 'aws-amplify';

import { AuthProvider } from './contexts/AwsCognitoContext';

//
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { LicenseInfo } from '@mui/x-license-pro';

import { AwsConfig } from './config';
console.log('config = ', AwsConfig);

Amplify.configure({
    aws_appsync_graphqlEndpoint: AwsConfig.appsync.graphqlEndpoint,
    aws_appsync_region: AwsConfig.cognito.REGION,
    aws_appsync_authenticationType: AwsConfig.appsync.authenticationType,
    aws_appsync_apiKey: AwsConfig.appsync.apiKey,
    Auth: {
        mandatorySignIn: false,
        region: AwsConfig.cognito.REGION,
        userPoolId: AwsConfig.cognito.USER_POOL_ID,
        identityPoolId: AwsConfig.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: AwsConfig.cognito.APP_CLIENT_ID,
    },
    Storage: {
        AWSS3: {
            region: AwsConfig.s3.REGION,
            bucket: AwsConfig.s3.BUCKET,
        },
    },
    API: {
        endpoints: AwsConfig.apiGateway.endpoints,
        aws_appsync_region: AwsConfig.appsync.region,
        aws_appsync_graphqlEndpoint: AwsConfig.appsync.graphqlEndpoint,
        aws_appsync_authenticationType: AwsConfig.appsync.authenticationType,
        aws_appsync_apiKey: AwsConfig.appsync.apiKey,
    },
});

// MUI Premium key installation
LicenseInfo.setLicenseKey(process.env.REACT_APP_DATAGRID_LICENSE);

// ----------------------------------------------------------------------

ReactDOM.render(
    <AuthProvider>
        <HelmetProvider>
            <ReduxProvider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <SettingsProvider>
                            <CollapseDrawerProvider>
                                <BrowserRouter>
                                    <App />
                                </BrowserRouter>
                            </CollapseDrawerProvider>
                        </SettingsProvider>
                    </LocalizationProvider>
                </PersistGate>
            </ReduxProvider>
        </HelmetProvider>
    </AuthProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
