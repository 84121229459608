// @mui
import { enUS, frFR, zhCN, viVN, arSD } from '@mui/material/locale';
// routes
import { PATH_DASHBOARD } from './routes/paths';
import Condition from 'yup/lib/Condition';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app; // as '/dashboard/app'

/********* CRPM APIs *********/

// Set API service names here
const apiNames = [
    'tenant', 
    'user', 
    'patient', 
    'device', 
    'insurance', 
    'observation',
    'crpm',
];
const createEndpoint = name => {
    let apiLink = '';

    switch (process.env.REACT_APP_STAGE) {
        case 'devsoor':
            apiLink = process.env.REACT_APP_API_URL;
            break;
        case 'demo':
            apiLink = process.env.REACT_APP_API_URL;
            break;
        case 'prod':
            apiLink = process.env.REACT_APP_API_URL;
            break;
        default:
            apiLink = process.env.REACT_APP_API_URL;
            break;
    }
    const item = {
        name: name,
        endpoint: apiLink,
        region: process.env.REACT_APP_REGION,
    };
    return item;
};

const endpoints = [];
apiNames.forEach(item => {
    const ep = createEndpoint(item);
    endpoints.push(ep);
});

export const AwsConfig = {
    s3: {
        REGION: process.env.REACT_APP_REGION,
        BUCKET: process.env.REACT_APP_BUCKET,
    },
    apiGateway: {
        endpoints: endpoints,
    },
    cognito: {
        REGION: process.env.REACT_APP_REGION,
        USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
        APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID,
        IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
    },
    appsync: {
        graphqlEndpoint: process.env.REACT_APP_APPSYNC_URL,
        authenticationType: 'API_KEY',
        apiKey: process.env.REACT_APP_APPSYNC_APIKEY,
        region: process.env.REACT_APP_REGION,
    },
};

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
    MOBILE_HEIGHT: 64,
    MAIN_DESKTOP_HEIGHT: 88,
    DASHBOARD_DESKTOP_HEIGHT: 62,
    DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
    BASE_WIDTH: 260,
    DASHBOARD_WIDTH: 200,
    DASHBOARD_COLLAPSE_WIDTH: 88,
    //
    DASHBOARD_ITEM_ROOT_HEIGHT: 48,
    DASHBOARD_ITEM_SUB_HEIGHT: 40,
    DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
    NAVBAR_ITEM: 22,
    NAVBAR_ITEM_HORIZONTAL: 20,
};

export const UserRoles = {
    'Group Admin': 'tenantadmin',
    Physician: 'physician',
    'Nurse Manager': 'nursemanager',
    Nurse: 'nurse',
    'Medical Assistant': 'medicalassistant',
};

export const DAYS_OPTIONS = [
	{name: "1 day", value: 1},
	{name: "2 days", value: 2},
	{name: "3 days", value: 3},
	{name: "5 days", value: 5},
	{name: "7 days", value: 7},
	{name: "14 days", value: 14},
	{name: "30 days", value: 30},
]
export const BILLING_CODE= [
    "RPM",
    "CCM"
]
export const REVIEW_TYPE= [
    "call",
    "sms"
]

export const NOTE_PHRASES = {
  "Onboarding":["RPM Onboarding(99453): I called the patient as the device was delivered based on our tracking parameters. We set up the device and educated the patient regarding the use of the device and connected it with our software.",],
	//"99454: Patient was supplied the medical device and accessories needed to remotely monitor and collect patient generated health data via data transmission technology.",
	"yes": ["RPM Monitoring(99457/99458): Communicated with the PT m/l times since there onboarding. Stressed the importance of daily readings and discussed the readings thus far. Compliance with medications reenforced. Questions answered.",
	"RPM Monitoring(99457/99458): Patient's data in the system reviewed. I called and left Message to remind patient to do daily readings.",
    "RPM Monitoring(99457/99458): RPM Hypertension: Discussed with patient regarding remote patient monitoring data available in the dashboard. We encouraged patient to follow a heart-healthy diet (such as DASH diet, Mediterranean diet), low salt intake (no more than 2400 mg/day), perform moderate to vigorous activity (3-4 days a week averaging 40 minutes per session), moderate alcohol consumption and monitor blood pressure daily. We also emphasized the importance of medication compliance and made them aware regarding alarming symptoms regarding hypertension for which they need to seek medical advice immediately. Patient verbalized understanding of the plan. We will continue to monitor the patient in our dashboard.",
    "RPM Monitoring(99457/99458): RPM Diabetes: Discussed with patient regarding remote patient monitoring data available in the dashboard. We encouraged patient to follow a balanced diet with a focus on carbohydrate counting, DASH diet or Mediterranean diet, perform moderate to vigorous activity (3-4 days a week averaging 40 minutes per session), moderate alcohol consumption and monitor blood glucose daily. We also emphasized the importance of medication compliance and made them aware regarding alarming symptoms related to hypoglycemia and/or hyperglycemia for which they need to seek medical advice immediately. We encouraged them for regular eye and foot examinations and if they notice any abnormality, they should bring it to their doctors’ attention. Patient verbalized understanding of the plan. We will continue to monitor the patient in our dashboard."],
    "no": [
      "RPM Monitoring(99457/99458): Patient's data in the system reviewed. I communicated with the patient to remind the patient to do daily readings.",
      "RPM Monitoring(99457/99458): RPM Hypertension: Communicated with the patient regarding remote patient monitoring data available in the dashboard. We encouraged patient to follow a heart-healthy diet (such as DASH diet, Mediterranean diet), low salt intake (no more than 2400 mg/day), perform moderate to vigorous activity (3-4 days a week averaging 40 minutes per session), moderate alcohol consumption and monitor blood pressure daily. We also emphasized the importance of medication compliance and made them aware regarding alarming symptoms regarding hypertension for which they need to seek medical advice immediately. We will continue to monitor the patient in our dashboard.",
      "RPM Monitoring(99457/99458): RPM Diabetes: Communicated with the patient regarding remote patient monitoring data available in the dashboard. We encouraged patient to follow a balanced diet with a focus on carbohydrate counting, DASH diet or Mediterranean diet, perform moderate to vigorous activity (3-4 days a week averaging 40 minutes per session), moderate alcohol consumption and monitor blood glucose daily. We also emphasized the importance of medication compliance and made them aware regarding alarming symptoms related to hypoglycemia and/or hyperglycemia for which they need to seek medical advice immediately. We encouraged them for regular eye and foot examinations and if they notice any abnormality, they should bring it to their doctors' attention. We will continue to monitor the patient in our dashboard."
    ]
}
export const PATIENT_TYPE = [
    "RPM",
    "CCM",
    "BOTH"
]


// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
    themeMode: 'light',
    themeDirection: 'ltr',
    themeContrast: 'default',
    themeLayout: 'horizontal',
    themeColorPresets: 'default',
    themeStretch: false,
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
    {
        label: 'English',
        value: 'en',
        systemValue: enUS,
        icon: '/assets/icons/flags/ic_flag_en.svg',
    },
    {
        label: 'French',
        value: 'fr',
        systemValue: frFR,
        icon: '/assets/icons/flags/ic_flag_fr.svg',
    },
    {
        label: 'Vietnamese',
        value: 'vn',
        systemValue: viVN,
        icon: '/assets/icons/flags/ic_flag_vn.svg',
    },
    {
        label: 'Chinese',
        value: 'cn',
        systemValue: zhCN,
        icon: '/assets/icons/flags/ic_flag_cn.svg',
    },
    {
        label: 'Arabic (Sudan)',
        value: 'ar',
        systemValue: arSD,
        icon: '/assets/icons/flags/ic_flag_sa.svg',
    },
];

export const CHRONIC_CONDTIONS = [{
    condition: "Osteoporosis",
    treatmentGoals : `1. Optimize Calcium and Vitamin D Levels:
   - Goal: Achieve and maintain optimal serum calcium and vitamin D levels.
   - Measurement: Regular blood tests to monitor serum calcium and vitamin D levels; adjust supplements as needed.
   #### Please add your notes on the goal here######

2. Medication Adherence:
   - Goal: Maintain at least 90% adherence to prescribed osteoporosis medications.
   - Measurement: Regularly review medication logs and assess patient compliance during follow-up appointments.
   #### Please add your notes on the goal here######

3. Improve Physical Activity:
   - Goal: Engage in weight-bearing exercises for at least 150 minutes per week.
   - Measurement: Record and monitor weekly physical activity through an exercise log or activity tracker.
   #### Please add your notes on the goal here######

4. Maintain a Balanced Diet:
   - Goal: Consume a diet rich in calcium and other bone-supporting nutrients.
   - Measurement: Periodic dietary assessments to ensure adequate intake of calcium, vitamin D, and other essential nutrients.
   #### Please add your notes on the goal here######

5. Monitor and Manage Side Effects:
   - Goal: Minimize and manage medication-related side effects.
   - Measurement: Regularly assess and document side effects; adjust medication or provide supportive care as needed.
   #### Please add your notes on the goal here######

6. Regular Follow-up Bone Density Testing:
   - Goal: Schedule follow-up DXA scans every 1-2 years to monitor progress.
   - Measurement: Adherence to recommended follow-up DXA scans within the specified timeframe.
   #### Please add your notes on the goal here######
    `,
    tenantSpecificGoals: {
      2522215142: `1. Optimize Calcium and Vitamin D Levels :
   - Goal: Ensure that tenant A patients reach optimal levels faster.
   - Measurement: Weekly blood tests for more precise monitoring.
   #### Please add your notes on the goal here######
   `,
      3157945203: `1. Optimize Calcium and Vitamin D Levels (Tenant B):
   - Goal: Focus on maintaining existing levels rather than rapid optimization.
   - Measurement: Monthly monitoring with dietary adjustments.
   #### Please add your notes on the goal here######
   `,
    },
  },
{
condition: "Osteoarthritis",
treatmentGoals:
`1. Pain Reduction:
   - Goal: Decrease pain intensity by 50% within the next three months.
   - Measurement: Use a pain scale (e.g., Visual Analog Scale) to assess pain levels regularly during follow-up appointments.
   #### Please add your notes on the goal here######

2. Improved Joint Function:
   - Goal: Increase affected joint/joints range of motion.
   - Measurement: Use functional assessments to monitor changes in joint mobility.
   #### Please add your notes on the goal here######

3. Enhanced Physical Function:
   - Goal: Improve functional ability, such as walking distance or climbing stairs, by 50% in the next four months.
   - Measurement: Assess physical function through patient-reported outcomes.
   #### Please add your notes on the goal here######

4. Medication Management:
   - Goal: Achieve effective pain control with minimal medication side effects.
   - Measurement: Regularly assess pain levels and monitor medication side effects during follow-up visits.
   #### Please add your notes on the goal here######

5. Weight Management:
   - Goal: Achieve ideal body weight within six- nine months to reduce stress on weight-bearing joints.
   - Measurement: Regularly track and monitor weight through follow-up appointments.
   #### Please add your notes on the goal here######

6. Patient Education and Self-Management:
    - Goal: Increase patient understanding of osteoarthritis and adherence to self-management strategies.
    - Measurement: Conduct regular assessments of patient knowledge and self-management practices.
    #### Please add your notes on the goal here######
    `,
    
},
{
condition: "Hypertension",
treatmentGoals:
`1. Blood Pressure Control:
   - Goal: Achieve and maintain blood pressure at or below the target level recommended by current guidelines (e.g., systolic < 130 mm Hg, diastolic < 80 mm Hg).
   - Measurement: Regular blood pressure monitoring at home or in clinical settings.
   #### Please add your notes on the goal here######

2. Lifestyle Modifications:
   - Goal: Implement lifestyle changes to lower blood pressure.
   - Measurement: Track and document dietary changes, physical activity levels, and weight loss progress.
   #### Please add your notes on the goal here######

3. Weight Management:
   - Goal: Achieve and maintain a healthy weight, aiming for a BMI within the normal range.
   - Measurement: Regularly measure and track weight during follow-up appointments.
   #### Please add your notes on the goal here######

4. Dietary Sodium Reduction:
   - Goal: Reduce daily sodium intake to less than 2 grams per day.
   - Measurement: Monitor food labels, track daily sodium intake, and make adjustments to the diet accordingly.
   #### Please add your notes on the goal here######

5. Regular Physical Activity:
   - Goal: Engage in moderate-intensity aerobic exercise for at least 150 minutes per week.
   - Measurement: Track weekly physical activity using an exercise log or activity tracker.
   #### Please add your notes on the goal here######

6. Medication Adherence:
   - Goal: Maintain at least 90% adherence to prescribed antihypertensive medications.
   - Measurement: Regularly review medication logs and assess patient compliance during follow-up appointments.
   #### Please add your notes on the goal here######

7. Home Blood Pressure Monitoring:
    - Goal: Perform regular home blood pressure monitoring as recommended by the healthcare provider.
    - Measurement: Adherence to the recommended frequency of home blood pressure measurements.
    #### Please add your notes on the goal here######
    `,
},
{

condition: "Diabetes",
treatmentGoals:
`
1. Blood Glucose Control:
   - Goal: Achieve and maintain target blood glucose levels as recommended by healthcare providers (e.g., fasting glucose levels of 100 mg/dL, postprandial levels of < 180mg/dL, 1 to 2 hours after meals).
   - Measurement: Regular self-monitoring of blood glucose levels using a glucose meter.
   #### Please add your notes on the goal here######

2. Hemoglobin A1c (HbA1c) Target:
   - Goal: Maintain HbA1c levels within the target range recommended by healthcare providers 
   - Measurement: Periodic HbA1c tests every three to six months.
   #### Please add your notes on the goal here######

3. Weight Management:
   - Goal: Achieve and maintain a healthy weight, aiming for a BMI within the normal range.
   - Measurement: Regularly measure and track weight during follow-up appointments.
   #### Please add your notes on the goal here######

4. Lifestyle Modifications:
   - Goal: Implement and sustain healthy lifestyle changes, including a balanced diet and regular physical activity.
   - Measurement: Document dietary habits, exercise routines, and changes in physical activity levels.
   #### Please add your notes on the goal here######

5. Medication Adherence:
   - Goal: Maintain at least 90% adherence to prescribed diabetes medications, including insulin if applicable.
   - Measurement: Regularly review medication logs and assess patient compliance during follow-up appointments.
   #### Please add your notes on the goal here######

6. Foot Care:
    - Goal: Practice daily foot care routines to prevent complications such as neuropathy or infections.
    - Measurement: Regular foot examinations and adherence to foot care guidelines.
    #### Please add your notes on the goal here######

7. Eye Examinations:
    - Goal: Schedule and attend regular eye examinations to monitor for diabetes-related eye complications.
    - Measurement: Adherence to recommended eye examination schedules.
    #### Please add your notes on the goal here######
    `
},
{
condition: "Hyperlipidemia",
treatmentGoals:
`1. LDL-C Reduction:
  - Goal: Achieve and maintain an LDL-C level within the target range.
  - Measurable Target: Reduce LDL-C levels to less than 100 mg/dL for general population or even lower for high-risk individuals (e.g., less than 70 mg/dL).
  #### Please add your notes on the goal here######

2. Total Cholesterol Management:
  - Goal: Attain and maintain total cholesterol levels within recommended limits.
  - Measurable Target: Keep total cholesterol below 200 mg/dL.
  #### Please add your notes on the goal here######

3. HDL-C Increase:
  - Goal: Increase levels of high-density lipoprotein cholesterol.
  - Measurable Target: Raise HDL-C levels to at least 40 mg/dL for men and 50 mg/dL for women.
  #### Please add your notes on the goal here######

4. Triglyceride Control:
  - Goal: Manage and reduce elevated triglyceride levels.
  - Measurable Target: Keep triglyceride levels below 150 mg/dL.
  #### Please add your notes on the goal here######

5. Lifestyle Modification:
  - Goal: Incorporate and maintain healthy lifestyle changes.
  - Measurable Target: Encourage regular exercise, a heart-healthy diet, smoking cessation, and weight management.
  #### Please add your notes on the goal here######
  
6. Monitoring and Follow-up:
  - Goal: Regularly assess lipid levels and adjust treatment as needed.
  - Measurable Target: Schedule follow-up appointments to monitor lipid profiles, assess medication adherence, and make necessary adjustments.
  #### Please add your notes on the goal here######
  `
},
{condition: "Rheumatoid Arthritis",
treatmentGoals:
`1. Pain Management:
  - Goal: Decrease pain levels and improve the patient’s pain tolerance.
  - Measurement: Use pain scales (e.g., visual analog scale) to quantify pain intensity and track changes over time.
  #### Please add your notes on the goal here######

2. Prevent Joint Damage:
  - Goal: Slow or prevent the progression of joint damage.
  - Measurement: Regular imaging studies such as X-rays or MRI to evaluate joint erosion and space narrowing.
  #### Please add your notes on the goal here######

3. Minimize Side Effects of Medications:
  - Goal: Manage and minimize the side effects of RA medications.
  - Measurement: Regularly review and address any adverse effects reported by the patient, and adjust treatment plans accordingly.
  #### Please add your notes on the goal here######

4. Medication Adherence:
  - Goal: Ensure consistent adherence to prescribed medications.
  - Measurement: Monitor medication adherence through patient self-reports, pharmacy records, or pill counts.
  #### Please add your notes on the goal here######

5. Reduce Inflammation:
  - Goal: Minimize inflammation as indicated by decreased ESR and CRP levels.
  - Measurement: Regular monitoring of inflammatory markers through blood tests.
  #### Please add your notes on the goal here######

6. Maintain or Improve Joint Function:
  - Goal: Preserve or enhance joint function and prevent disability.
  - Measurement: Regular assessments of joint function or physical examinations.
  #### Please add your notes on the goal here######
  
`},
{
    condition: "Atrial fibrillation",
    treatmentGoals: `measurable treatment goals for atrial fibrillation:
    1. Rate or Rhythm Control:
      - Goal: Achieve and maintain appropriate heart rate or rhythm control to alleviate symptoms associated with AFib, such as palpitations, fatigue, and shortness of breath.
      - Measurement: Regular assessment of heart rate and rhythm through electrocardiograms (ECGs) or ambulatory monitoring, with a target heart rate or rhythm determined in consultation with the patient and healthcare provider.
    2. Anticoagulation Therapy:
      - Goal: Reduce the risk of stroke and systemic embolism by optimizing anticoagulation therapy in patients with AFib who are at moderate to high risk of stroke.
      - Measurement: Adherence to anticoagulation therapy as prescribed, regular monitoring of international normalized ratio (INR) for patients on warfarin, or adherence to direct oral anticoagulant (DOAC) therapy, with periodic assessment of renal function and bleeding risk.
    3. Cardiovascular Risk Factor Management:
      - Goal: Address modifiable cardiovascular risk factors (e.g., hypertension, diabetes, hyperlipidemia, obesity) to reduce the risk of cardiovascular events and improve outcomes in patients with AFib.
      - Measurement: Achievement of target blood pressure, lipid levels, glycemic control, and body weight, as recommended by clinical guidelines and individualized treatment plans.
    4. Stroke and Bleeding Risk Assessment:
      - Goal: Accurately assess the risk of stroke and bleeding in patients with AFib using validated risk assessment tools (e.g., CHA2DS2-VASc score, HAS-BLED score) and implement appropriate preventive measures based on individual risk profiles.
      - Measurement: Regular review and documentation of stroke and bleeding risk assessments, with appropriate adjustments to antithrombotic therapy and lifestyle modifications as indicated.
      Use these calculators to access the risk
        https://www.mdcalc.com/calc/801/cha2ds2-vasc-score-atrial-fibrillation-stroke-risk
        https://www.mdcalc.com/calc/807/has-bled-score-major-bleeding-risk
    5. Patient Education and Adherence:
      - Goal: Provide patient education on AFib, its management, and the importance of treatment adherence to improve outcomes and reduce complications.
      - Measurement: Assessment of patient understanding of AFib and its treatment, adherence to prescribed medications and lifestyle recommendations, and engagement in shared decision-making regarding treatment options.
    6. Regular Follow-Up and Monitoring:
      - Goal: Ensure regular follow-up and monitoring of patients with AFib to assess treatment efficacy, monitor for complications, and adjust treatment plans as needed.
      - Measurement: Adherence to scheduled follow-up appointments, documentation of clinical assessments, and appropriate use of diagnostic tests (e.g., ECG, echocardiography) to monitor disease progression and treatment response.`
},
{
    condition: "Heart Failure",
    treatmentGoals: `1. Symptom Control:
    - Goal: Achieve relief from symptoms such as dyspnea, fatigue, orthopnea, and edema.
    - Measurement: Use New York Heart Association [NYHA] functional classification to quantify symptom severity and track changes over time.
    ##use below link to classify and update the output here##
        https://www.mdcalc.com/calc/3987/new-york-heart-association-nyha-functional-classification-heart-failure
  2. Fluid Management:
    - Goal: Achieve and maintain euvolemia to minimize symptoms of fluid overload (e.g., dyspnea, edema) and prevent exacerbations.
    - Measurement: Monitoring of daily weights, assessment of fluid intake/output, and adjustment of diuretic therapy to achieve target dry weight without causing dehydration or electrolyte imbalances.
  3. Optimize Medication Therapy:
    - Goal: Maximize guideline-directed medical therapy (GDMT) to improve symptoms, reduce hospitalizations, and prolong survival.
    - Measurement: Adherence to evidence-based medications such as angiotensin-converting enzyme inhibitors (ACEIs), angiotensin receptor blockers (ARBs), beta-blockers, mineralocorticoid receptor antagonists (MRAs), and sodium-glucose co-transporter-2 (SGLT2) inhibitors, with dose titration as tolerated to target doses or maximum tolerated doses.
  4. Reduce Hospitalizations:
    - Goal: Minimize hospitalizations due to heart failure exacerbations and related complications.
    - Measurement: Reduction in the frequency and duration of hospital admissions for heart failure, as documented in medical records.
  5. Improve Exercise Tolerance:
    - Goal: Enhance functional capacity and exercise tolerance to improve quality of life and reduce symptoms of fatigue and dyspnea.
    - Measurement: Objective assessment of exercise capacity using standardized exercise tests (e.g., 6-minute walk test) and documentation of improvements over time. Document 6- minute walk distance using the following calculator, and ask patients to walk for 6 minutes with step count or distance walked in feet.
        https://www.mdcalc.com/calc/3983/6-minute-walk-distance
  6. Dietary and Fluid Management:
    - Goal: Implement dietary modifications (e.g., sodium restriction, less than 2 gm per day or instructed by physician) and fluid management strategies to optimize symptom control and reduce the risk of fluid overload and exacerbations.
    - Measurement: Adherence to dietary recommendations, monitoring of sodium intake, and education on fluid restriction, with periodic assessment of nutritional status and electrolyte balance.
  7. Monitor Biomarkers:
    - Goal: Use biomarkers such as B-type natriuretic peptide (BNP) or N-terminal pro-B-type natriuretic peptide (NT-proBNP) to assess disease severity, guide treatment decisions, and monitor response to therapy.
    - Measurement: Regular measurement of BNP or NT-proBNP levels and interpretation in the context of clinical status and treatment goals.`
},{
    condition: "Asthma",
    treatmentGoals: `1. Symptom Control:
    - Goal: Achieve and maintain symptom control, including the reduction of coughing, wheezing, chest tightness, and shortness of breath.
    - Measurement: Use of validated symptom assessment tools like the Asthma Control Test (ACT) to monitor symptoms regularly.
 The Asthma Control Test (ACT) is a tool designed to assess how well asthma symptoms are being managed. The scores on the ACT range from 5 to 25, with higher scores indicating better asthma control. Based on the provided information, here's how the scores and their interpretations can be categorized:
 All questions are scored on a scale of 1 to 5, with 1 being the least controlled and 5 being the most controlled.
 Scoring Breakdown:
 Question 1 (Work, School, or Home Impact):
 1: All of the time
 2: Most of the time
 3: Some of the time
 4: A little of the time
 5: None of the time
 Question 2 (Frequency of Shortness of Breath):
 1: More than once a day
 2: Once a day
 3: 3 to 6 times a week
 4: Once or twice a week
 5: Not at all
 Question 3 (Nighttime Symptoms):
 1: All of the time
 2: Most of the time
 3: Some of the time
 4: A little of the time
 5: None of the time
 Question 4 (Rescue Medication Usage):
 1: 3 or more times per day
 2: 1 or 2 times per day
 3: 2 or 3 times per week
 4: Once a week or less
 5: Not at all
 Question 5 (Self-Rated Asthma Control):
 1: Not controlled at all
 2: Poorly controlled
 3: Somewhat controlled
 4: Well controlled
 5: Completely controlled
 Interpretation:
 If the total score is 19 or less, it suggests that asthma may not be as well controlled as it could be. Lower scores indicate a need for further evaluation and potentially adjusting the asthma management plan.
 It's important for individuals to discuss their ACT scores with their healthcare provider to determine the most appropriate course of action for managing their asthma symptoms
 
 2. Lung Function Improvement:
    - Goal: Improve lung function as measured by spirometry parameters such as forced expiratory volume in one second (FEV1) or peak expiratory flow rate (PEFR).
    - Measurement: Regular pulmonary function tests (PFTs) to assess lung function and track changes over time.
 
 3. Prevention of Exacerbations:
    - Goal: Minimize the frequency and severity of asthma exacerbations.
    - Measurement: Reduction in the number of asthma exacerbations requiring oral corticosteroids, emergency department visits, or hospitalizations over a specified period.
 
 4. Optimize Medication Management:
    - Goal: Achieve and maintain asthma control with the lowest effective dose of medications.
    - Measurement: Regular assessment of medication adherence, adjustment of medication regimens based on symptom control and lung function, and monitoring for side effects.
 
 5. Environmental Control:
    - Goal: Minimize exposure to asthma triggers in the patient's environment.
    - Measurement: Implementation of environmental control measures and reduction in exposure to known triggers, as evidenced by patient-reported changes in symptoms and triggers.
 
 6. Patient Education and Self-Management:
    - Goal: Empower the patient to effectively self-manage asthma and recognize early signs of worsening symptoms.
    - Measurement: Assessment of patient knowledge and skills related to asthma self-management through discussions, education sessions, and validated questionnaires.`
},
{
    condition: "Depression",
    treatmentGoals: `Screening for Psychosocial Issues (Use only for patients with history of Depression/Anxiety)
    Key: 0: Not at all, 1: Several days, 2: More than half the days 3: Nearly every day
    1. Little interest or pleasure in doing things 
    2. Feeling down, depressed, or hopeless
    3. Trouble falling or staying asleep, or sleeping too much
    4. Feeling tired or having little energy
    5. Poor appetite or overeating
    6. Feeling bad about yourself or that you are a failure or have let yourself or your family down.
    7. Trouble concentrating on things, such as reading the newspaper or watching television.
    8. Moving or speaking so slowly that other people could have noticed. Or the opposite being so figety or restless that you have been moving around a lot more than usual
    9. Thoughts that you would be better off dead, or of hurting yourself

    Result: 
    Total Score     Depression Severity
    1-4             Minimal depression
    5-9             Mild depression
    10-14           Moderate depression
    15-19           Moderately severe depression
    20-27           Severe depression 
    `
},
{
    condition: "Anemia",
    treatmentGoals: `1. Increase Hemoglobin Levels:
    - Goal: Raise hemoglobin levels to within the normal range for the patient’s age, sex, and medical condition.
    - Measurement: Regular monitoring of hemoglobin levels through blood tests to assess response to treatment.
  2. Improve Symptoms:
    - Goal: Alleviate symptoms associated with anemia, such as fatigue, weakness, shortness of breath, dizziness, and pale skin.
    - Measurement: Monitor and track changes over time.
  3. Optimize Iron Status:
    - Goal: Replenish iron stores and restore iron balance in cases of iron deficiency anemia.
    - Measurement: Monitoring of serum ferritin, transferrin saturation, and other markers of iron status to assess response to iron supplementation or other iron-repletion therapies.
  4. Minimize Transfusion Dependence:
    - Goal: Reduce dependence on blood transfusions by addressing underlying causes of anemia and implementing appropriate treatments.
    - Measurement: Monitoring of hemoglobin levels and transfusion requirements over time to assess the need for ongoing transfusion support.
  5. Optimize Treatment Compliance:
    - Goal: Ensure patient compliance with prescribed treatments, including medication regimens, dietary modifications, and lifestyle recommendations.
    - Measurement: Regular assessment of treatment adherence through patient self-reports, medication diaries, or objective measures (e.g., pill counts).
  6. Monitor Response to Therapy:
    - Goal: Regularly monitor response to anemia treatment to assess efficacy, adjust treatment as needed, and prevent recurrence or progression of anemia.
    - Measurement: Periodic evaluation of hemoglobin levels, symptoms, and markers of iron status or other relevant parameters to track response to therapy.`
},{
    condition: "CKD",
    treatmentGoals: `1. Preserve Kidney Function:
    - Goal: Slow the progression of CKD and preserve residual kidney function.
    - Measurement: Regular monitoring of estimated glomerular filtration rate (eGFR) and urine albumin-to-creatinine ratio (UACR) to assess kidney function and progression of kidney damage.
  2. Blood Pressure Control:
    - Goal: Achieve and maintain target blood pressure levels to reduce the risk of cardiovascular events and slow the progression of CKD.
    - Measurement: Monitoring of blood pressure daily and adjustment of antihypertensive medications to achieve target blood pressure goals (e.g., <130/80 mmHg for most patients with CKD).
  3. Anemia Management:
    - Goal: Manage anemia associated with CKD to improve quality of life and reduce the risk of cardiovascular complications.
    - Measurement: Regular monitoring of hemoglobin levels and appropriate use of erythropoiesis-stimulating agents (ESAs) or iron supplementation to maintain target hemoglobin levels (e.g., 10-11.5 g/dL).
  4. Cardiovascular Risk Reduction:
    - Goal: Reduce cardiovascular risk factors (e.g., hypertension, dyslipidemia, diabetes) to prevent cardiovascular events and complications in patients with CKD.
    - Measurement: Management of modifiable cardiovascular risk factors through lifestyle modifications and pharmacological interventions, with regular monitoring of blood pressure, lipid levels, and glycemic control.
  5. Medication Review and Optimization:
    - Goal: Review and optimize medication regimens to minimize the risk of adverse drug reactions and drug interactions in patients with CKD.
    - Measurement: Regular medication reviews to assess appropriateness, dosing adjustments based on kidney function, and monitoring for potential adverse effects.
  6. Patient Education and Self-Management:
    - Goal: Empower patients with CKD to actively participate in their care, adhere to treatment plans, and adopt healthy lifestyle behaviors to improve outcomes.
    - Measurement: Provision of comprehensive education on CKD management, including medication adherence, dietary modifications, fluid management, and symptom recognition, with regular assessment of patient knowledge and self-management skills.`
},{
    condition: "COPD",
    treatmentGoals: `1. Symptom Relief:
    - Goal: Alleviate symptoms such as dyspnea (shortness of breath), cough, and sputum production to improve the patient’s overall comfort and quality of life.
    - Measurement: Assessment of symptoms and monitoring of symptom response to treatment.
  2. Exercise Tolerance Improvement:
  - Goal: Enhance functional capacity and exercise tolerance to improve quality of life and reduce symptoms of fatigue and dyspnea.
    - Measurement: Objective assessment of exercise capacity using standardized exercise tests (e.g., 6-minute walk test) and documentation of improvements over time. Document 6- minute walk distance using the following calculator, and ask patients to walk for 6 minutes with step count or distance walked in feet.
  https://www.mdcalc.com/calc/3983/6-minute-walk-distance
  3. Reduction in Exacerbations:
    - Goal: Minimize the frequency and severity of COPD exacerbations, which can lead to hospitalizations and worsen disease outcomes.
    - Measurement: Assessment of exacerbation frequency, severity, and duration, and monitoring of response to exacerbation prevention strategies (e.g., pharmacotherapy, vaccination, self-management education).
  4. Smoking Cessation:
    - Goal: Encourage smoking cessation to reduce the progression of COPD and improve treatment outcomes.
    - Measurement: Assessment of smoking status and provision of smoking cessation interventions (e.g., counseling, pharmacotherapy) to support smoking cessation efforts and monitor long-term abstinence.
  5. Optimization of Pharmacotherapy:
    - Goal: Optimize pharmacological treatment to control symptoms, reduce exacerbations, and improve quality of life.
    - Measurement: Assessment of medication adherence, efficacy, and side effects, with adjustment of pharmacotherapy based on symptom control and disease severity.
  6. Oxygen Therapy:
    - Goal: Provide supplemental oxygen therapy to improve oxygenation, relieve hypoxemia, and enhance exercise tolerance in patients with severe COPD and chronic respiratory failure.
    - Measurement: Monitoring of oxygen saturation levels, and response to oxygen therapy to ensure appropriate oxygenation and titration of oxygen flow rates.
  `
}
,{
    condition: "Fibromyalgia",
    treatmentGoals: `1. Pain Relief:
    - Goal: Alleviate pain and stiffness associated with polymyalgia rheumatica.
    - Measurement: Reduction in pain intensity and improvement in joint stiffness as assessed by patient-reported outcomes and clinical evaluation.
  2. Improvement in Sleep Quality:
    - Goal: Enhance sleep quality and promote restorative sleep.
    - Measurement: Improvement in sleep duration, sleep efficiency, and reduction in sleep disturbances as assessed by subjective sleep reports.
  3. Enhanced Physical Functioning:
    - Goal: Improve physical functioning and mobility.
    - Measurement: Increase in physical activity levels, improvement in functional capacity (e.g., ability to perform activities of daily living [ADLs], mobility), and enhancement in muscle strength and flexibility.
  4. Psychological Well-being:
    - Goal: Address psychological symptoms and enhance emotional well-being.
    - Measurement: Reduction in depression and anxiety symptoms as assessed by standardized psychological assessment tools [PHQ-9].
  5. Education and Self-Management:
    - Goal: Provide education about fibromyalgia and empower patients to actively manage their condition.
    - Measurement: Improvement in knowledge about fibromyalgia, adherence to treatment recommendations, and utilization of self-management strategies (e.g., pacing, stress management, relaxation techniques).
  6. Medication Management:
    - Goal: Optimize medication management to alleviate symptoms and improve functioning.
    - Measurement: Reduction in pain, fatigue, sleep disturbances, and other symptoms with appropriate use of pharmacological interventions (e.g., analgesics, antidepressants, anticonvulsants) as prescribed by healthcare providers.`
},{
    condition: "Polymyalgia",
    treatmentGoals: `1. Pain Relief:
    - Goal: Alleviate pain and stiffness associated with polymyalgia rheumatica.
    - Measurement: Reduction in pain intensity and improvement in joint stiffness as assessed by patient-reported outcomes and clinical evaluation.
  2. Improvement in Function:
    - Goal: Improve functional status and mobility to enhance daily activities.
    - Measurement: Increase in range of motion, improvement in ability to perform activities of daily living (ADLs), and enhancement in overall physical function.
  3. Prevention of Complications:
    - Goal: Minimize the risk of complications associated with polymyalgia rheumatica and its treatment.
    - Measurement: Monitoring for potential complications such as giant cell arteritis (GCA), osteoporosis, cardiovascular disease, and adverse effects of corticosteroid therapy.
  4. Minimization of Corticosteroid Use:
    - Goal: Achieve symptom control with the lowest effective dose of corticosteroids to minimize long-term adverse effects.
    - Measurement: Titration of corticosteroid dose based on symptom response, inflammatory markers, and overall disease activity as per physician’s recommendations.
  5. Management of Corticosteroid-Related Side Effects:
    - Goal: Monitor and manage potential side effects associated with corticosteroid therapy.
    - Measurement: Regular assessment of corticosteroid-related adverse effects (e.g., weight gain, osteoporosis, diabetes, hypertension) and implementation of preventive measures or treatment interventions as needed.
  6. Patient Education and Self-Management:
    - Goal: Provide education about polymyalgia rheumatica and empower patients to actively participate in their treatment and self-management.
    - Measurement: Improvement in patient knowledge about the condition, adherence to treatment recommendations, and utilization of self-management strategies (e.g., pacing, joint protection techniques).
  7. Regular Monitoring and Follow-Up:
     - Goal: Ensure regular monitoring of disease activity, treatment response, and potential complications.
     - Measurement: Regular clinical assessments, monitoring of inflammatory markers, and scheduled follow-up visits to adjust treatment as needed and address emerging issues.`
},{
    condition: "Alzheimer",
    treatmentGoals: `1. Activities of Daily Living (ADLs) Maintenance:
    - Goal: Maintain or improve the ability to perform basic activities of daily living independently, such as eating, dressing, grooming, and toileting.
    - Measurement: Assessment of ADLs using standardized scales like the Functional Activities Questionnaire as mentioned above.
  2. Safety Promotion, Caregiver Support and Education:
    - Goal: Ensure a safe environment for the patient by minimizing the risk of accidents, falls, and wandering behaviors, provide education, training, and support to caregivers to enhance their ability to manage the challenges of caregiving and maintain their own well-being.
    - Measurement: Implementation of safety measures (e.g., removing tripping hazards, installing door locks or alarms) and regular assessment of safety risks through caregiver reports or home safety evaluations. (Request Social Service home safety evaluations, whenever it is indicated).
  3. Medication Management:
    - Goal: Optimize medication management to address cognitive symptoms, manage behavioral symptoms, and address co-morbidities while minimizing side effects.
    - Measurement: Regular review of medications for appropriateness, monitoring for adverse effects, and assessment of medication adherence through caregiver reports or pill counts.
  4. Social Engagement and Stimulation:
    - Goal: Maintain social engagement and provide meaningful activities to stimulate cognitive function and promote emotional well-being.
    - Measurement: Regular participation in social activities, engagement in hobbies or interests, and assessment of social interactions and satisfaction with activities.
    `
},{
    condition: "Coronary arterial disease",
    treatmentGoals: `1. Prevention of Recurrent Stroke:
    - Goal: Reduce the risk of recurrent stroke and other vascular events through aggressive management of modifiable risk factors.
    - Measurement: Control of hypertension, diabetes, dyslipidemia, and lifestyle modifications (e.g., smoking cessation, healthy diet, regular exercise) to optimize vascular health.
  2. Neuroprotective Strategies:
    - Goal: Minimize secondary brain injury and promote neuroprotection to limit the extent of damage and enhance recovery.
    - Measurement: Administration of neuroprotective agents (e.g., aspirin, statins) and management of complications such as cerebral edema and seizures.
  3. Early Rehabilitation:
    - Goal: Initiate early rehabilitation interventions to promote functional recovery, prevent complications (e.g., contractures, pneumonia), and optimize recovery potential.
    - Measurement: Early mobilization, physical therapy, occupational therapy, speech therapy, and swallowing evaluation to address impairments and maximize functional independence. Request Home Health evaluation when indicated
  4. Secondary Prevention of Complications:
    - Goal: Prevent complications such as deep vein thrombosis (DVT), urinary tract infections (UTIs), pressure ulcers, and pneumonia.
    - Measurement: Implementation of preventive measures including pharmacological prophylaxis (e.g., anticoagulation, DVT prophylaxis), early mobilization, turning and positioning, and respiratory hygiene.
  5. Psychosocial Support:
    - Goal: Address emotional and psychological needs of patients and their families, reduce anxiety, depression, and post-stroke adjustment difficulties.
    - Measurement: Provision of emotional support, counseling, education about stroke recovery, and referral to support groups and community resources.
  6. Long-Term Rehabilitation and Support:
     - Goal: Facilitate long-term recovery and maximize functional independence through ongoing rehabilitation and support services.
     - Measurement: Continuation of rehabilitation therapies, assessment of progress, modification of treatment plans as needed, and coordination of care with rehabilitation specialists and community resources.`
},{
    condition: "Ischemic Heart Disease",
    treatmentGoals: `1. Prevention of Recurrent Stroke:
    - Goal: Reduce the risk of recurrent stroke and other vascular events through aggressive management of modifiable risk factors.
    - Measurement: Control of hypertension, diabetes, dyslipidemia, and lifestyle modifications (e.g., smoking cessation, healthy diet, regular exercise) to optimize vascular health.
  2. Neuroprotective Strategies:
    - Goal: Minimize secondary brain injury and promote neuroprotection to limit the extent of damage and enhance recovery.
    - Measurement: Administration of neuroprotective agents (e.g., aspirin, statins) and management of complications such as cerebral edema and seizures.
  3. Early Rehabilitation:
    - Goal: Initiate early rehabilitation interventions to promote functional recovery, prevent complications (e.g., contractures, pneumonia), and optimize recovery potential.
    - Measurement: Early mobilization, physical therapy, occupational therapy, speech therapy, and swallowing evaluation to address impairments and maximize functional independence. Request Home Health evaluation when indicated
  4. Secondary Prevention of Complications:
    - Goal: Prevent complications such as deep vein thrombosis (DVT), urinary tract infections (UTIs), pressure ulcers, and pneumonia.
    - Measurement: Implementation of preventive measures including pharmacological prophylaxis (e.g., anticoagulation, DVT prophylaxis), early mobilization, turning and positioning, and respiratory hygiene.
  5. Psychosocial Support:
    - Goal: Address emotional and psychological needs of patients and their families, reduce anxiety, depression, and post-stroke adjustment difficulties.
    - Measurement: Provision of emotional support, counseling, education about stroke recovery, and referral to support groups and community resources.
  6. Long-Term Rehabilitation and Support:
     - Goal: Facilitate long-term recovery and maximize functional independence through ongoing rehabilitation and support services.
     - Measurement: Continuation of rehabilitation therapies, assessment of progress, modification of treatment plans as needed, and coordination of care with rehabilitation specialists and community resources.`
},{
    condition: "Liver disease",
    treatmentGoals: `1. Manage Symptoms:
    - Goal: Alleviate symptoms associated with liver disease, such as fatigue, jaundice, pruritus, ascites, and hepatic encephalopathy.
    - Measurement: Monitor symptom severity and track changes over time.
  2. Slow or Halt Disease Progression:
    - Goal: Slow or halt the progression of liver disease to prevent further liver damage and complications.
    - Measurement: Regular monitoring of liver function tests, imaging studies, and disease-specific biomarkers to assess disease progression and response to treatment.
  3. Prevent Hepatic Decompensation:
    - Goal: Prevent hepatic decompensation in patients with advanced liver disease (e.g., cirrhosis) by optimizing medical management and monitoring for signs of decompensation (e.g., ascites, hepatic encephalopathy, variceal bleeding).
    - Measurement: Regular clinical assessment, liver function tests, imaging studies, and endoscopic evaluations to monitor for signs of hepatic decompensation.
  4. Optimize Nutritional Support:
    - Goal: Provide adequate nutritional support to patients with liver disease to maintain or improve nutritional status, prevent malnutrition, and support liver function.
    - Measurement: Nutritional assessment), dietary counseling, and supplementation as needed, with monitoring of nutritional status and response to interventions.
  5. Patient Education and Support:
     - Goal: Provide education and support to patients and caregivers regarding the nature of liver disease, treatment options, lifestyle modifications, and self-management strategies.
     - Measurement: Assessment of patient and caregiver knowledge, understanding, and ability to implement recommended treatments and lifestyle modifications.`
},{
    condition: "Obesity",
    treatmentGoals: `1. Achieve and Maintain Weight Loss:
    - Goal: Achieve a clinically meaningful weight loss of 5-10% of initial body weight within 6-12 months and sustain it long-term.
    - Measurement: Regular monitoring of body weight, body mass index (BMI), waist circumference, and body composition to track progress and adjust interventions as needed.
  2. Improve Cardiometabolic Health:
    - Goal: Improve cardiometabolic health markers, including blood pressure, lipid profile, blood glucose levels, and insulin sensitivity.
    - Measurement: Regular monitoring of blood pressure, lipid panel, fasting glucose, hemoglobin A1c (HbA1c), and other relevant biomarkers to assess improvements and guide treatment adjustments.
  3. Reduce Risk of Obesity-Related Complications:
    - Goal: Reduce the risk of obesity-related complications such as type 2 diabetes, cardiovascular disease, hypertension, dyslipidemia, fatty liver disease, sleep apnea, and certain cancers.
    - Measurement: Assessment of comorbidities, imaging studies (e.g., ultrasound for fatty liver), and other diagnostic tests to monitor for improvements in obesity-related complications
  4. Promote Healthy Eating Habits:
    - Goal: Promote adoption of a balanced, nutrient-dense diet that is sustainable and supports weight loss and overall health.
    - Measurement: Dietary assessment (e.g., food diary, 24-hour recall), monitoring of dietary adherence, and education on portion control, meal planning, and healthier food choices.
  5. Increase Physical Activity:
    - Goal: Encourage regular physical activity and reduce sedentary behavior to enhance energy expenditure and support weight loss.
    - Measurement: Objective assessment of physical activity levels (e.g., step count, minutes of moderate to vigorous physical activity) using activity trackers or self-report measures, and monitoring of adherence to physical activity recommendations.
  6. Address Psychosocial Factors:
    - Goal: Address psychosocial factors that may impact weight management (e.g., depression, anxiety, body image dissatisfaction) and support overall well-being.
    - Measurement: Screening for psychosocial issues using validated tools (e.g., Patient Health Questionnaire [PHQ-9] for depression), referral to mental health professionals as needed, and monitoring of changes in psychosocial well-being.`
},{
    condition: "Senile dementia",
    treatmentGoals: `1. Activities of Daily Living (ADLs) Maintenance:
    - Goal: Maintain or improve the ability to perform basic activities of daily living independently, such as eating, dressing, grooming, and toileting.
    - Measurement: Assessment of ADLs using standardized scales like the Functional Activities Questionnaire as mentioned above.
  2. Safety Promotion, Caregiver Support and Education:
    - Goal: Ensure a safe environment for the patient by minimizing the risk of accidents, falls, and wandering behaviors, provide education, training, and support to caregivers to enhance their ability to manage the challenges of caregiving and maintain their own well-being.
    - Measurement: Implementation of safety measures (e.g., removing tripping hazards, installing door locks or alarms) and regular assessment of safety risks through caregiver reports or home safety evaluations. (Request Social Service home safety evaluations, whenever it is indicated).
  3. Medication Management:
    - Goal: Optimize medication management to address cognitive symptoms, manage behavioral symptoms, and address comorbidities while minimizing side effects.
    - Measurement: Regular review of medications for appropriateness, monitoring for adverse effects, and assessment of medication adherence through caregiver reports or pill counts.
  4. Social Engagement and Stimulation:
    - Goal: Maintain social engagement and provide meaningful activities to stimulate cognitive function and promote emotional well-being.
    - Measurement: Regular participation in social activities, engagement in hobbies or interests, and assessment of social interactions and satisfaction with activities.
  5. Regular Monitoring and Follow-Up:
     - Goal: Ensure regular monitoring of disease progression, treatment response, and overall well-being of individuals with dementia.
     - Measurement: Scheduled follow-up visits with healthcare providers, cognitive assessments, medication reviews, and adjustments in care plans as needed based on individual needs and preferences.`
},{
    condition: "Breast Cancer",
    treatmentGoals: `1. Prevention of Recurrence:
    - Goal: Minimize the risk of breast cancer recurrence, including local recurrence in the breast or regional lymph nodes, as well as distant metastasis.
    - Measurement: Regular surveillance with physical examinations, imaging studies (e.g., mammography, MRI, PET-CT), and tumor markers (e.g., CA 15-3, CA 27.29) to detect recurrence or metastasis early.
  2. Improved Survival:
    - Goal: Improve overall survival and disease-free survival rates for patients with breast cancer.
    - Measurement: Long-term follow-up to assess survival outcomes, including overall survival, disease-free survival, and distant recurrence-free survival rates, and comparison with historical data or benchmarks.
  3. Preservation of Quality of Life:
    - Goal: Minimize treatment-related side effects and preserve or improve the patient’s quality of life during and after treatment.
    - Measurement: Monitoring and management of treatment-related side effects (e.g., fatigue, pain, nausea, alopecia, lymphedema, cognitive dysfunction) through supportive care interventions and rehabilitation services.
  4. Psychosocial Support:
    - Goal: Provide psychosocial support to address the emotional, social, and psychological needs of patients and their families throughout the breast cancer journey.
    - Measurement: Access to psychosocial support services (e.g., counseling, support groups, survivorship programs) to address emotional distress, anxiety, depression, body image issues, and adjustment to survivorship.
  5. Long-Term Survivorship Care:
     - Goal: Provide comprehensive survivorship care to address the ongoing needs of breast cancer survivors, including surveillance for recurrence, management of late effects of treatment, and promotion of healthy lifestyle behaviors.
     - Measurement: Implementation of survivorship care plans, regular follow-up visits, and coordination of care with primary care providers and specialists to optimize long-term health outcomes and quality of life.`
},{
    condition: "Stroke/ TIA- transient ischemic attack",
    treatmentGoals: `1. Prevention of Recurrent Stroke:
    - Goal: Reduce the risk of recurrent stroke and other vascular events through aggressive management of modifiable risk factors.
    - Measurement: Control of hypertension, diabetes, dyslipidemia, and lifestyle modifications (e.g., smoking cessation, healthy diet, regular exercise) to optimize vascular health.
  2. Neuroprotective Strategies:
    - Goal: Minimize secondary brain injury and promote neuroprotection to limit the extent of damage and enhance recovery.
    - Measurement: Administration of neuroprotective agents (e.g., aspirin, statins) and management of complications such as cerebral edema and seizures.
  3. Early Rehabilitation:
    - Goal: Initiate early rehabilitation interventions to promote functional recovery, prevent complications (e.g., contractures, pneumonia), and optimize recovery potential.
    - Measurement: Early mobilization, physical therapy, occupational therapy, speech therapy, and swallowing evaluation to address impairments and maximize functional independence. Request Home Health evaluation when indicated
  4. Secondary Prevention of Complications:
    - Goal: Prevent complications such as deep vein thrombosis (DVT), urinary tract infections (UTIs), pressure ulcers, and pneumonia.
    - Measurement: Implementation of preventive measures including pharmacological prophylaxis (e.g., anticoagulation, DVT prophylaxis), early mobilization, turning and positioning, and respiratory hygiene.
  5. Psychosocial Support:
    - Goal: Address emotional and psychological needs of patients and their families, reduce anxiety, depression, and post-stroke adjustment difficulties.
    - Measurement: Provision of emotional support, counseling, education about stroke recovery, and referral to support groups and community resources.
  6. Long-Term Rehabilitation and Support:
     - Goal: Facilitate long-term recovery and maximize functional independence through ongoing rehabilitation and support services.
     - Measurement: Continuation of rehabilitation therapies, assessment of progress, modification of treatment plans as needed, and coordination of care with rehabilitation specialists and community resources.`
},{
    condition: "Lung Cancer",
    treatmentGoals: `1. Prevention of Recurrence:
    - Goal: Minimize the risk of lung cancer recurrence, including local recurrence in the lung or mediastinum, regional lymph node recurrence, and distant metastasis.
    - Measurement: Regular surveillance with imaging studies (e.g., CT scan, PET-CT) and tumor markers (e.g., CEA) to detect recurrence or metastasis early.
  2. Improved Survival:
    - Goal: Improve overall survival and progression-free survival rates for patients with lung cancer.
    - Measurement: Long-term follow-up to assess survival outcomes, including overall survival, progression-free survival, and response rates to treatment, and comparison with historical data or benchmarks.
  3. Minimization of Treatment Toxicity:
    - Goal: Minimize treatment-related toxicity and adverse effects while optimizing treatment efficacy.
    - Measurement: Monitoring and management of treatment-related side effects (e.g., nausea, vomiting, fatigue, neuropathy, hematologic toxicity) through supportive care interventions, dose modifications, and close monitoring of treatment tolerance.
  4. Preservation of Lung Function:
    - Goal: Preserve lung function and optimize quality of life after lung cancer treatment, including preservation of pulmonary function and avoidance of significant respiratory compromise.
    - Measurement: Assessment of pulmonary function tests (e.g., spirometry, diffusion capacity) and provision of pulmonary rehabilitation services as needed to optimize lung function and respiratory symptoms.
  5. Psychosocial Support:
    - Goal: Provide psychosocial support to address the emotional, social, and psychological needs of patients and their families throughout the lung cancer journey.
    - Measurement: Access to psychosocial support services (e.g., counseling, support groups, survivorship programs) to address emotional distress, anxiety, depression, coping strategies, and adjustment to diagnosis and treatment.
  6. Long-Term Survivorship Care:
     - Goal: Provide comprehensive survivorship care to address the ongoing needs of lung cancer survivors, including surveillance for recurrence, management of late effects of treatment, and promotion of healthy lifestyle behaviors.
     - Measurement: Implementation of survivorship care plans, regular follow-up visits, and coordination of care with primary care providers and specialists to optimize long-term health outcomes and quality of life.`
},{
    condition: "Colorectal cancer",
    treatmentGoals: `1. Prevention of Recurrence:
    - Goal: Minimize the risk of colorectal cancer recurrence, including local recurrence in the colon or rectum, regional lymph node recurrence, and distant metastasis.
    - Measurement: Regular surveillance with physical examinations, imaging studies (e.g., CT scan, MRI, PET-CT), and tumor markers (e.g., CEA) to detect recurrence or metastasis early.
  2. Improved Survival:
    - Goal: Improve overall survival and disease-free survival rates for patients with colorectal cancer.
    - Measurement: Long-term follow-up to assess survival outcomes, including overall survival, disease-free survival, and recurrence-free survival rates, and comparison with historical data or benchmarks.
  3. Minimization of Treatment Toxicity:
    - Goal: Minimize treatment-related toxicity and adverse effects while optimizing treatment efficacy.
    - Measurement: Monitoring and management of treatment-related side effects (e.g., nausea, vomiting, diarrhea, neuropathy, hematologic toxicity) through supportive care interventions, dose modifications, and close monitoring of treatment tolerance.
  4. Preservation of Bowel Function:
    - Goal: Preserve bowel function and optimize quality of life after colorectal cancer surgery, including preservation of continence and avoidance of permanent stomas whenever possible.
    - Measurement: Assessment of bowel function, continence, and quality of life outcomes and provision of rehabilitation services as needed.
  5. Psychosocial Support:
    - Goal: Provide psychosocial support to address the emotional, social, and psychological needs of patients and their families throughout the colorectal cancer journey.
    - Measurement: Access to psychosocial support services (e.g., counseling, support groups, survivorship programs) to address emotional distress, anxiety, depression, body image issues, and adjustment to survivorship.
  6. Long-Term Survivorship Care:
     - Goal: Provide comprehensive survivorship care to address the ongoing needs of colorectal cancer survivors, including surveillance for recurrence, management of late effects of treatment, and promotion of healthy lifestyle behaviors.
     - Measurement: Implementation of survivorship care plans, regular follow-up visits, and coordination of care with primary care providers and specialists to optimize long-term health outcomes and quality of life.`
},{
    condition: "Endometrial cancer ",
    treatmentGoals: `1. Prevention of Disease Progression:
    - Goal: Prevent the progression of localized endometrial cancer to advanced or metastatic disease.
    - Measurement: Regular monitoring of cancer markers (e.g., CA-125), imaging studies, and clinical symptoms to detect disease progression early and adjust treatment as needed.
  2. Minimization of Treatment Side Effects:
    - Goal: Minimize treatment-related side effects and adverse effects while optimizing treatment efficacy.
    - Measurement: Monitoring and management of treatment-related side effects (e.g., nausea, vomiting, fatigue, hematologic toxicity) through supportive care interventions, dose modifications, and close monitoring of treatment tolerance.
  3. Preservation of Bladder and Bowel Function:
    - Goal: Preserve bladder and bowel function and minimize the risk of urinary and bowel complications during and after endometrial cancer treatment.
    - Measurement: Assessment of bladder and bowel function (e.g., urinary continence, bowel continence) and provision of supportive care interventions (e.g., pelvic floor exercises, dietary modifications) as needed to optimize function and minimize complications.
  4. Psychosocial Support:
    - Goal: Provide psychosocial support to address the emotional, social, and psychological needs of patients and their families throughout the endometrial cancer journey.
    - Measurement: Access to psychosocial support services (e.g., counseling, support groups, survivorship programs) to address emotional distress, anxiety, depression, coping strategies, and adjustment to diagnosis and treatment.
  5. Long-Term Survivorship Care:
     - Goal: Provide comprehensive survivorship care to address the ongoing needs of endometrial cancer survivors, including surveillance for recurrence, management of late effects of treatment, and promotion of healthy lifestyle behaviors.
     - Measurement: Implementation of survivorship care plans, regular follow-up visits, and coordination of care with primary care providers and specialists to optimize long-term health outcomes and quality of life.`
}
,{
    condition: "Prostatic Hyperplasia",
    treatmentGoals: `1. Prevention of Disease Progression:
    - Goal: Prevent the progression of localized prostate cancer to advanced or metastatic disease.
    - Measurement: Regular monitoring of prostate-specific antigen (PSA) levels, clinical symptoms, and imaging studies to detect disease progression early and adjust treatment as needed.
  2. Preservation of Urinary and Sexual Function:
    - Goal: Preserve urinary continence and sexual function while treating prostate cancer.
    - Measurement: Assessment of urinary function (e.g., urinary incontinence, urinary retention) and sexual function (e.g., erectile dysfunction) before and after treatment, with provision of supportive care interventions (e.g., pelvic floor exercises, erectile dysfunction medications, penile rehabilitation) as needed.
  3. Minimization of Treatment Side Effects:
    - Goal: Minimize treatment-related side effects and adverse effects while optimizing treatment efficacy.
    - Measurement: Monitoring and management of treatment-related side effects (e.g., urinary incontinence, erectile dysfunction, bowel dysfunction, fatigue) through supportive care interventions, dose modifications, and close monitoring of treatment tolerance.
  4. Preservation of Bowel Function:
    - Goal: Preserve bowel function and minimize the risk of bowel complications during and after prostate cancer treatment.
    - Measurement: Assessment of bowel function (e.g., bowel continence, bowel urgency) and provision of supportive care interventions (e.g., dietary modifications, bowel management strategies) as needed to optimize bowel function and minimize complications.
  5. Psychosocial Support:
    - Goal: Provide psychosocial support to address the emotional, social, and psychological needs of patients and their families throughout the prostate cancer journey.
    - Measurement: Access to psychosocial support services (e.g., counseling, support groups, survivorship programs) to address emotional distress, anxiety, depression, coping strategies, and adjustment to diagnosis and treatment.
  6. Long-Term Survivorship Care:
     - Goal: Provide comprehensive survivorship care to address the ongoing needs of prostate cancer survivors, including surveillance for recurrence, management of late effects of treatment, and promotion of healthy lifestyle behaviors.
     - Measurement: Implementation of survivorship care plans, regular follow-up visits, and coordination of care with primary care providers and specialists to optimize long-term health outcomes and quality of life.`
},{
    condition: "Glaucoma",
    treatmentGoals: ""
}
,{
    condition: "Obstructive Sleep Apnea (OSA)",
    treatmentGoals: `
    1. Symptom Relief:
    - Goal: Alleviate symptoms of obstructive sleep apnea such as snoring, daytime sleepiness, fatigue, and morning headaches.
    - Measurement: Reduction in symptom severity and improvement in overall well-being as reported by the patient.
  2. Reduction in Cardiovascular Risk:
    - Goal: Lower the risk of cardiovascular complications associated with untreated obstructive sleep apnea, including hypertension, coronary artery disease, stroke, and arrhythmias.
    - Measurement: Monitoring and management of cardiovascular risk factors (e.g., blood pressure, lipid profile, glucose levels) and assessment of cardiovascular outcomes over time.
  3. Normalization of Oxygen Levels:
    - Goal: Maintain adequate oxygenation during sleep and prevent nocturnal hypoxemia, which can have detrimental effects on organ systems.
    - Measurement: Monitoring of oxygen saturation levels during sleep and assessment of oxygen desaturation index (ODI) to evaluate the effectiveness of treatment in preventing nocturnal hypoxemia.
  4. Prevention of Complications:
    - Goal: Minimize the risk of complications associated with untreated obstructive sleep apnea, such as motor vehicle accidents, cognitive impairment, and mood disturbances.
    - Measurement: Assessment of accident rates, cognitive function, mood, and quality of life to monitor for improvements and prevent adverse outcomes.
  5. Weight Management:
    - Goal: Achieve and maintain a healthy weight to reduce the severity of obstructive sleep apnea and improve treatment outcomes.
    - Measurement: Monitoring of body weight, body mass index (BMI), and waist circumference, and implementation of dietary and lifestyle interventions to promote weight loss when appropriate.
  6. Compliance with Treatment:
    - Goal: Ensure adherence to prescribed treatment modalities, such as continuous positive airway pressure (CPAP) therapy or oral appliance therapy.
    - Measurement: Assessment of treatment adherence through objective measures (e.g., device usage data, download compliance data) and subjective reports from patients regarding comfort, tolerability, and perceived benefit.
    `
},
{
    condition: "Gastroesophageal Reflux Disease (GERD)",
    treatmentGoals: `1. Symptom Relief:
    - Goal: Alleviate symptoms of heartburn, regurgitation, chest pain, and acid reflux.
    - Measurement: Reduction in frequency, severity, and duration of symptoms as reported by the patient
  2. Healing of Esophageal Damage:
    - Goal: Promote healing of esophageal mucosal damage (e.g., erosions, ulcers) caused by acid reflux and inflammation.
    - Measurement: Endoscopic evaluation to assess the extent of mucosal injury and resolution of esophageal erosions or ulcers with treatment.
  3. Relief of Nocturnal Symptoms:
    - Goal: Minimize nocturnal symptoms of GERD, including nighttime heartburn, regurgitation, and sleep disturbances.
    - Measurement: Reduction in nocturnal symptoms and improvement in sleep quality as reported by the patient.
  4. Weight Management:
    - Goal: Achieve and maintain a healthy weight to reduce intra-abdominal pressure and decrease the risk of GERD symptoms.
    - Measurement: Monitoring of body weight, body mass index (BMI), and waist circumference, and implementation of dietary and lifestyle interventions to promote weight loss when appropriate.
  5. Optimization of Treatment Modalities:
     - Goal: Select and optimize treatment modalities (e.g., lifestyle modifications, medications, surgery) based on symptom severity, patient preferences, and treatment response.
     - Measurement: Evaluation of treatment adherence, symptom control, and complications to determine the need for adjustment or modification of treatment strategies.
  6. Long-Term Management and Prevention of Recurrence:
     - Goal: Provide long-term management strategies to control symptoms, prevent recurrence, and maintain esophageal health.
     - Measurement: Implementation of maintenance therapy, regular follow-up visits, patient education, and lifestyle modifications to optimize long-term outcomes and minimize the need for additional interventions.
    `
},{
    condition: "Inflammatory Bowel Disease (IBD)", 
    treatmentGoals: `
    1. Symptom Control:
    - Goal: Alleviate gastrointestinal symptoms such as abdominal pain, diarrhea, rectal bleeding, and urgency.
    - Measurement: Reduction in symptom severity and frequency as reported by the patient and confirmed through clinical evaluation.
  2. Mucosal Healing:
    - Goal: Promote healing of the intestinal mucosa and minimize mucosal inflammation to prevent disease progression and reduce the risk of complications.
    - Measurement: Endoscopic evaluation to assess mucosal inflammation and healing, with the goal of achieving mucosal remission.
  3. Minimization of Steroid Use:
    - Goal: Limit the use of corticosteroids to induce remission and avoid long-term steroid dependence and associated adverse effects.
    - Measurement: Reduction in steroid use through the use of steroid-sparing medications (e.g., immunomodulators, biologic agents) and optimization of maintenance therapy to prevent disease flares.
  4. Optimization of Medication Therapy:
    - Goal: Select and optimize medication therapy based on disease phenotype, severity, and response to treatment, with the goal of achieving optimal disease control and minimizing side effects.
    - Measurement: Evaluation of treatment adherence, response to therapy, and monitoring for medication-related adverse effects to guide treatment adjustments.
  5. Psychosocial Support:
     - Goal: Address the emotional and psychological impact of IBD, including anxiety, depression, stress, and impaired quality of life.
     - Measurement: Provision of psychosocial support services (e.g., counseling, support groups) to help patients cope with the challenges of living with a chronic illness and improve overall well-being.
  6. Patient Education and Empowerment:
     - Goal: Provide comprehensive education about IBD, treatment options, self-management strategies, and lifestyle modifications to empower patients to actively participate in their care and make informed decisions.
     - Measurement: Improvement in patient knowledge, self-management skills, treatment adherence, and ability to recognize and manage disease flares and complications.
    `
},
{
  condition:  "Crohn’s disease",
  treatmentGoals: `
  1. Symptom Control:
  - Goal: Alleviate gastrointestinal symptoms such as abdominal pain, diarrhea, rectal bleeding, and urgency.
  - Measurement: Reduction in symptom severity and frequency as reported by the patient and confirmed through clinical evaluation.
2. Mucosal Healing:
  - Goal: Promote healing of the intestinal mucosa and minimize mucosal inflammation to prevent disease progression and reduce the risk of complications.
  - Measurement: Endoscopic evaluation to assess mucosal inflammation and healing, with the goal of achieving mucosal remission.
3. Minimization of Steroid Use:
  - Goal: Limit the use of corticosteroids to induce remission and avoid long-term steroid dependence and associated adverse effects.
  - Measurement: Reduction in steroid use through the use of steroid-sparing medications (e.g., immunomodulators, biologic agents) and optimization of maintenance therapy to prevent disease flares.
4. Optimization of Medication Therapy:
  - Goal: Select and optimize medication therapy based on disease phenotype, severity, and response to treatment, with the goal of achieving optimal disease control and minimizing side effects.
  - Measurement: Evaluation of treatment adherence, response to therapy, and monitoring for medication-related adverse effects to guide treatment adjustments.
5. Psychosocial Support:
   - Goal: Address the emotional and psychological impact of IBD, including anxiety, depression, stress, and impaired quality of life.
   - Measurement: Provision of psychosocial support services (e.g., counseling, support groups) to help patients cope with the challenges of living with a chronic illness and improve overall well-being.
6. Patient Education and Empowerment:
   - Goal: Provide comprehensive education about IBD, treatment options, self-management strategies, and lifestyle modifications to empower patients to actively participate in their care and make informed decisions.
   - Measurement: Improvement in patient knowledge, self-management skills, treatment adherence, and ability to recognize and manage disease flares and complications.
  `
},
{
  condition:   "Ulcerative colitis",
  treatmentGoals: `
  1. Symptom Control:
  - Goal: Alleviate gastrointestinal symptoms such as abdominal pain, diarrhea, rectal bleeding, and urgency.
  - Measurement: Reduction in symptom severity and frequency as reported by the patient and confirmed through clinical evaluation.
2. Mucosal Healing:
  - Goal: Promote healing of the intestinal mucosa and minimize mucosal inflammation to prevent disease progression and reduce the risk of complications.
  - Measurement: Endoscopic evaluation to assess mucosal inflammation and healing, with the goal of achieving mucosal remission.
3. Minimization of Steroid Use:
  - Goal: Limit the use of corticosteroids to induce remission and avoid long-term steroid dependence and associated adverse effects.
  - Measurement: Reduction in steroid use through the use of steroid-sparing medications (e.g., immunomodulators, biologic agents) and optimization of maintenance therapy to prevent disease flares.
4. Optimization of Medication Therapy:
  - Goal: Select and optimize medication therapy based on disease phenotype, severity, and response to treatment, with the goal of achieving optimal disease control and minimizing side effects.
  - Measurement: Evaluation of treatment adherence, response to therapy, and monitoring for medication-related adverse effects to guide treatment adjustments.
5. Psychosocial Support:
   - Goal: Address the emotional and psychological impact of IBD, including anxiety, depression, stress, and impaired quality of life.
   - Measurement: Provision of psychosocial support services (e.g., counseling, support groups) to help patients cope with the challenges of living with a chronic illness and improve overall well-being.
6. Patient Education and Empowerment:
   - Goal: Provide comprehensive education about IBD, treatment options, self-management strategies, and lifestyle modifications to empower patients to actively participate in their care and make informed decisions.
   - Measurement: Improvement in patient knowledge, self-management skills, treatment adherence, and ability to recognize and manage disease flares and complications.
  `
},
{
    condition: "Barrett’s esophagus",
    treatmentGoals: `1. Management of Gastroesophageal Reflux Disease (GERD) Symptoms:
    - Goal: Alleviate symptoms associated with GERD, such as heartburn, regurgitation, chest pain, and difficulty swallowing.
    - Measurement: Reduction in frequency and severity of GERD symptoms through lifestyle modifications, medications, and, if necessary, surgical interventions.
  2. Prevention of Esophageal Cancer:
    - Goal: Reduce the risk of esophageal adenocarcinoma development in patients with Barrett’s esophagus.
    - Measurement: Implementation of strategies to prevent cancer progression, including surveillance endoscopy, acid suppression therapy, and, in some cases, endoscopic or surgical interventions to remove precancerous or cancerous lesions.
  3. Optimization of Acid Suppression Therapy:
    - Goal: Achieve effective acid suppression to reduce esophageal inflammation and promote healing of Barrett’s esophagus.
    - Measurement: Monitoring of treatment adherence and optimization of proton pump inhibitors (PPIs) or other acid-suppressive medications to maintain symptom control and prevent disease progression.
  4. Lifestyle Modifications:
    - Goal: Implement lifestyle changes to reduce acid reflux and improve overall esophageal health.
    - Measurement: Adoption of dietary modifications (e.g., avoiding trigger foods, eating smaller meals), weight management, elevation of the head of the bed, and smoking cessation to minimize reflux symptoms and prevent esophageal injury.
  5. Patient Education and Adherence:
    - Goal: Provide education about Barrett’s esophagus, risk factors for cancer development, and the importance of surveillance and treatment adherence.
    - Measurement: Improvement in patient understanding of the condition, treatment options, and the need for regular surveillance endoscopy to detect dysplasia or cancer at an early stage.
  6. Psychological Support:
     - Goal: Address emotional and psychological concerns related to the diagnosis of Barrett’s esophagus, surveillance procedures, and cancer risk.
     - Measurement: Provision of psychosocial support, counseling, and coping strategies to reduce anxiety, depression, and distress associated with the condition.`
},{
    condition: "Fatty Liver disease",
    treatmentGoals: `1. Management of Underlying Conditions:
    - Goal: Address underlying conditions contributing to fatty liver disease, such as obesity, insulin resistance, diabetes, dyslipidemia, and metabolic syndrome.
    - Measurement: Optimization of metabolic parameters including body weight, blood glucose levels, insulin sensitivity, lipid profile, and blood pressure to reduce hepatic fat accumulation and improve overall metabolic health.
  2. Lifestyle Modifications:
    - Goal: Implement lifestyle changes to promote weight loss, improve dietary habits, increase physical activity, and reduce alcohol consumption.
    - Measurement: Achievement of weight loss goals, adoption of a balanced diet rich in fruits, vegetables, whole grains, and lean proteins, engagement in regular exercise, and cessation or moderation of alcohol intake.
  3. Prevention of Liver Fibrosis and Cirrhosis:
    - Goal: Prevent or slow the progression of liver fibrosis and cirrhosis, which are potential complications of fatty liver disease.
    - Measurement: Monitoring of liver fibrosis markers (e.g., FibroScan, FibroTest, transient elastography) and assessment of liver stiffness to identify patients at risk of advanced liver disease and guide treatment decisions.
  4. Improvement in Liver Function:
    - Goal: Improve liver function tests (e.g., alanine aminotransferase [ALT], aspartate aminotransferase [AST], gamma-glutamyl transferase [GGT]) and markers of hepatocyte injury and inflammation.
    - Measurement: Reduction in liver enzyme levels and normalization of liver function tests with effective treatment and lifestyle modifications.
  5. Management of Coexisting Conditions:
    - Goal: Address comorbidities such as nonalcoholic steatohepatitis (NASH), type 2 diabetes, hypertension, and cardiovascular disease to reduce the risk of liver-related and cardiovascular complications.
    - Measurement: Optimization of treatment for coexisting conditions and reduction in associated morbidity and mortality risks.
  6. Patient Education and Adherence:
     - Goal: Provide education about fatty liver disease, its risk factors, complications, and the importance of adherence to lifestyle modifications and treatment recommendations.
     - Measurement: Improvement in patient knowledge, understanding of the disease process, and adherence to treatment and lifestyle recommendations as evidenced by behavioral changes and treatment compliance.`
},
{
  condition: "Pancreatic Exocrine Insufficiency (PEI)",
  treatmentGoals: `1. Relief of Gastrointestinal Symptoms:
  - Goal: Alleviate symptoms of malabsorption, including abdominal pain, bloating, diarrhea, steatorrhea (fatty stools), and weight loss.
  - Measurement: Reduction in symptom severity and frequency as reported by the patient and improvement in stool consistency and frequency.
2. Pancreatic Enzyme Replacement Therapy (PERT):
  - Goal: Provide exogenous pancreatic enzymes to compensate for deficient endogenous enzyme secretion and facilitate digestion and absorption of nutrients.
  - Measurement: Achievement of adequate enzyme dosing to achieve symptom control and normalization of stool consistency, with adjustment of dosage as needed based on response to treatment and dietary intake.
3. Dietary Modifications:
  - Goal: Implement dietary modifications to reduce fat malabsorption, optimize nutrient absorption, and improve tolerance of pancreatic enzyme replacement therapy.
  - Measurement: Adoption of a low-fat diet, smaller and more frequent meals, and avoidance of high-fat or greasy foods to minimize symptoms of malabsorption and improve dietary adherence.
4. Management of Associated Conditions:
  - Goal: Address underlying conditions that may contribute to or exacerbate PEI, such as chronic pancreatitis, cystic fibrosis, pancreatic cancer, or gastrointestinal surgeries.
  - Measurement: Management of comorbidities and complications to optimize pancreatic function, improve nutritional status, and enhance overall health outcomes.
5. Patient Education and Adherence:
   - Goal: Provide education about PEI, its causes, symptoms, and treatment options, and promote adherence to prescribed medications, dietary recommendations, and lifestyle modifications.
   - Measurement: Improvement in patient knowledge, understanding of PEI management strategies, treatment adherence, and ability to recognize and manage symptoms and complications.
6. Monitoring and Follow-Up:
   - Goal: Ensure regular monitoring of symptoms, nutritional status, pancreatic function, and treatment response through periodic clinical evaluations, laboratory tests, and imaging studies.
   - Measurement: Scheduled follow-up visits with healthcare providers, monitoring of symptom control, assessment of nutritional parameters, and adjustment of treatment plans based on disease progression and individual patient needs.
`
},{
  condition: "Cirrhosis of Liver",
  treatmentGoals: `1. Manage Symptoms:
  - Goal: Alleviate symptoms associated with liver disease, such as fatigue, jaundice, pruritus, ascites, and hepatic encephalopathy.
  - Measurement: Monitor symptom severity and track changes over time.
2. Slow or Halt Disease Progression:
  - Goal: Slow or halt the progression of liver disease to prevent further liver damage and complications.
  - Measurement: Regular monitoring of liver function tests, imaging studies, and disease-specific biomarkers to assess disease progression and response to treatment.
3. Prevent Hepatic Decompensation:
  - Goal: Prevent hepatic decompensation in patients with advanced liver disease (e.g., cirrhosis) by optimizing medical management and monitoring for signs of decompensation (e.g., ascites, hepatic encephalopathy, variceal bleeding).
  - Measurement: Regular clinical assessment, liver function tests, imaging studies, and endoscopic evaluations to monitor for signs of hepatic decompensation.
4. Optimize Nutritional Support:
  - Goal: Provide adequate nutritional support to patients with liver disease to maintain or improve nutritional status, prevent malnutrition, and support liver function.
  - Measurement: Nutritional assessment), dietary counseling, and supplementation as needed, with monitoring of nutritional status and response to interventions.
5. Patient Education and Support:
   - Goal: Provide education and support to patients and caregivers regarding the nature of liver disease, treatment options, lifestyle modifications, and self-management strategies.
   - Measurement: Assessment of patient and caregiver knowledge, understanding, and ability to implement recommended treatments and lifestyle modifications.
`
},{
  condition: "Gastroparesis",
  treatmentGoals: `1. Symptom Relief:
  - Goal: Alleviate symptoms associated with gastroparesis, including nausea, vomiting, early satiety, bloating, abdominal pain, and reflux.
  - Measurement: Reduction in symptom severity and frequency as reported by the patient, improvement in quality of life, and ability to tolerate oral intake without discomfort.
2. Dietary Modifications:
  - Goal: Implement dietary modifications to facilitate gastric emptying, minimize symptoms, and optimize nutritional intake.
  - Measurement: Adoption of a low-fat, low-fiber diet consisting of smaller, more frequent meals, and avoidance of foods that exacerbate symptoms (e.g., high-fat, high-fiber, or gas-producing foods).
3. Nutritional Support:
  - Goal: Address malnutrition, dehydration, and micronutrient deficiencies associated with gastroparesis by optimizing oral intake and providing nutritional supplementation when necessary.
  - Measurement: Improvement in nutritional parameters, including weight gain or maintenance, normalization of serum albumin and prealbumin levels, and resolution of micronutrient deficiencies with appropriate supplementation.
4. Medication Management:
  - Goal: Use medications to alleviate symptoms, improve gastric motility, and reduce complications associated with gastroparesis.
  - Measurement: Assessment of symptom control and treatment response with medications such as prokinetic agents (e.g., metoclopramide, domperidone), antiemetics, prokinetic agents, and symptom-targeted therapies (e.g., antiemetics for nausea and vomiting).
5. Glycemic Control in Diabetic Gastroparesis:
  - Goal: Optimize glycemic control to reduce fluctuations in blood glucose levels and improve symptoms and gastric motility in patients with diabetic gastroparesis.
  - Measurement: Monitoring of blood glucose levels, glycated hemoglobin (HbA1c), and symptoms of gastroparesis to assess the impact of glycemic control on disease management.
6. Patient Education and Adherence:
   - Goal: Provide education about gastroparesis, its causes, symptoms, treatment options, and self-management strategies to empower patients to actively participate in their care and make informed decisions.
   - Measurement: Improvement in patient knowledge, understanding of gastroparesis management strategies, treatment adherence, and ability to recognize and manage symptoms and complications.`
},{
  condition: "Alcoholic hepatitis",
  treatmentGoals: `
  1. Alcohol Abstinence:
  - Goal: Achieve and maintain complete abstinence from alcohol to prevent further liver damage and improve liver function.
  - Measurement: Confirmation of alcohol abstinence through self-reporting, laboratory testing (e.g., serum ethanol levels, carbohydrate-deficient transferrin [CDT]), and support from addiction specialists or support groups.
2. Liver Inflammation Reduction:
  - Goal: Reduce liver inflammation and minimize hepatocellular injury associated with alcoholic hepatitis.
  - Measurement: Improvement in liver enzyme levels (e.g., alanine aminotransferase [ALT], aspartate aminotransferase [AST]) and markers of inflammation (e.g., C-reactive protein [CRP], erythrocyte sedimentation rate [ESR]) with treatment interventions.
3. Management of Complications:
  - Goal: Identify and manage complications of alcoholic hepatitis, such as hepatic encephalopathy, coagulopathy, ascites, hepatic steatosis, and hepatic fibrosis.
  - Measurement: Monitoring for signs and symptoms of complications, including hepatic encephalopathy (e.g., altered mental status), coagulopathy (e.g., prolonged prothrombin time [PT]), ascites (e.g., abdominal distension), and hepatic fibrosis (e.g., imaging studies, liver biopsy).
4. Nutritional Support:
  - Goal: Address malnutrition and nutrient deficiencies commonly observed in patients with alcoholic hepatitis to improve liver function and promote healing.
  - Measurement: Assessment of nutritional status, micronutrient deficiencies (e.g., thiamine, folate, vitamin B12), and markers of malnutrition (e.g., albumin, prealbumin), with implementation of nutritional support strategies as needed (e.g., oral supplements, enteral or parenteral nutrition).
5. Treatment of Coexisting Conditions:
  - Goal: Manage concurrent medical conditions that may exacerbate alcoholic hepatitis or complicate its treatment, such as infections, metabolic disorders, or comorbid liver diseases.
  - Measurement: Identification and treatment of coexisting conditions to optimize patient outcomes and prevent complications.
6. Patient Education and Adherence:
   - Goal: Provide education about alcoholic hepatitis, its causes, symptoms, and treatment options, and promote adherence to treatment recommendations and lifestyle modifications.
   - Measurement: Improvement in patient knowledge, understanding of the consequences of alcohol use, treatment adherence, and willingness to engage in behavioral changes to support liver health and prevent alcohol-related liver injury.
  `
},
{
  condition: "Irritable Bowel Syndrome (IBS)",
  treatmentGoals: `1. Symptom Management:
  - Goal: Alleviate gastrointestinal symptoms such as abdominal pain, bloating, diarrhea, constipation, and changes in bowel habits.
  - Measurement: Reduction in symptom severity and frequency as reported by the patient, assessed through validated symptom assessment tools (e.g., IBS Symptom Severity Score), and improvement in quality of life.
2. Normalization of Bowel Function:
  - Goal: Restore normal bowel function and promote regularity of bowel movements.
  - Measurement: Improvement in stool consistency, frequency, and urgency, aiming for normalization of bowel habits.
3. Pain Relief:
  - Goal: Reduce the intensity and frequency of abdominal pain and discomfort associated with IBS.
  - Measurement: Reduction in pain scores and improvement in overall abdominal comfort as reported by the patient.
4. Dietary Modification:
  - Goal: Identify and manage dietary triggers that exacerbate IBS symptoms and optimize nutrition.
  - Measurement: Reduction in symptom severity with dietary modifications, such as a low-FODMAP diet, fiber supplementation, or avoidance of trigger foods.
5. Stress Reduction:
  - Goal: Minimize stress and anxiety, which can exacerbate IBS symptoms.
  - Measurement: Implementation of stress management techniques (e.g., relaxation exercises, mindfulness, cognitive-behavioral therapy) to improve coping skills and reduce psychological distress.
6. Medication Management:
  - Goal: Use medications to target specific symptoms and improve overall symptom control in IBS.
  - Measurement: Evaluation of treatment response and side effects with medications such as antispasmodics, laxatives, antidiarrheals, antidepressants, or gut-targeted therapies (e.g., rifaximin for IBS-D).
7. Education and Self-Management:
  - Goal: Provide education about IBS, triggers, treatment options, and self-management strategies to empower patients to manage their condition effectively.
  - Measurement: Improvement in patient knowledge, self-management skills, treatment adherence, and ability to recognize and manage symptom triggers and exacerbations.`
},{
  condition: "Chronic constipation",
  treatmentGoals: `
  1. Symptom Relief:
  - Goal: Alleviate symptoms of constipation, including infrequent bowel movements, difficulty passing stools, straining, incomplete evacuation, and abdominal discomfort.
  - Measurement: Reduction in symptom severity and frequency as reported by the patient, improvement in bowel habits, and relief of associated discomfort.
2. Normalization of Bowel Function:
  - Goal: Restore regularity of bowel movements and promote normal stool consistency and frequency.
  - Measurement: Achievement of regular, soft, formed bowel movements without excessive straining or discomfort, aiming for at least three bowel movements per week.
3. Improvement in Stool Consistency:
  - Goal: Optimize stool consistency to facilitate easier passage and reduce discomfort.
  - Measurement: Normalization of stool consistency on the Bristol Stool Scale, aiming for type 3 or type 4 stools (soft and easy to pass).
4. Dietary Modifications:
  - Goal: Implement dietary changes to increase fiber intake, improve stool bulk, and promote regular bowel movements.
  - Measurement: Adoption of a high-fiber diet rich in fruits, vegetables, whole grains, and legumes, with adequate fluid intake to soften stools and facilitate passage.
5. Fluid Intake Optimization:
  - Goal: Ensure adequate hydration to maintain stool consistency and prevent dehydration-related constipation.
  - Measurement: Promotion of adequate fluid intake (e.g., water, herbal teas, electrolyte-rich beverages) to support optimal bowel function and hydration status.
6. Lifestyle Modifications:
  - Goal: Encourage lifestyle changes such as regular physical activity, maintaining a healthy weight, and establishing a consistent bowel routine to promote regularity.
  - Measurement: Engagement in regular exercise, weight management, and establishment of a regular bowel routine (e.g., scheduling time for bowel movements) to support bowel regularity.
7. Patient Education and Self-Management:
   - Goal: Provide education about chronic constipation, its causes, treatment options, and self-management strategies to empower patients to manage their condition effectively.
   - Measurement: Improvement in patient knowledge, understanding of constipation management strategies, treatment adherence, and ability to recognize and manage symptoms and exacerbations.
  `
},{
  condition: "Collagenous colitis",
  treatmentGoals: `
  1. Symptom Relief:
  - Goal: Alleviate symptoms of collagenous colitis, including chronic watery diarrhea, abdominal pain, urgency, and fecal incontinence.
  - Measurement: Reduction in symptom severity and frequency as reported by the patient, improvement in stool consistency and bowel habits, and relief of associated discomfort.
2. Normalization of Bowel Function:
  - Goal: Restore regularity of bowel movements and promote normal stool consistency.
  - Measurement: Achievement of formed or semi-formed stools with less frequent bowel movements, aiming for improved bowel regularity and decreased urgency.
3. Reduction of Diarrhea:
  - Goal: Decrease stool frequency and volume to alleviate diarrhea and improve fecal consistency.
  - Measurement: Reduction in the number of bowel movements per day, improvement in stool consistency, and resolution of chronic diarrhea.
4. Management of Abdominal Pain:
  - Goal: Minimize abdominal pain and discomfort associated with collagenous colitis.
  - Measurement: Reduction in abdominal pain severity and frequency, improvement in overall abdominal comfort, and relief of associated symptoms.
5. Correction of Fluid and Electrolyte Imbalance:
  - Goal: Address fluid and electrolyte abnormalities secondary to chronic diarrhea, such as dehydration, electrolyte imbalances, and nutritional deficiencies.
  - Measurement: Normalization of electrolyte levels (e.g., sodium, potassium) and improvement in hydration status with appropriate fluid replacement and electrolyte supplementation.
6. Preservation of Nutritional Status:
  - Goal: Prevent malnutrition and nutritional deficiencies by optimizing nutrient absorption and maintaining adequate dietary intake.
  - Measurement: Assessment of nutritional status, micronutrient levels, and weight stability, with implementation of dietary modifications or nutritional supplementation as needed.
7. Patient Education and Self-Management:
   - Goal: Provide education about collagenous colitis, its causes, treatment options, and self-management strategies to empower patients to manage their condition effectively.
   - Measurement: Improvement in patient knowledge, understanding of collagenous colitis management strategies, treatment adherence, and ability to recognize and manage symptoms and exacerbations.
  `
},
// {
//   condition: "Atrial fibrillation",
//   treatmentGoals: ``
// }

];

export const defaultLang = allLangs[0]; // English
