// import PropTypes from 'prop-types';
// import { RHFTextField } from '../hook-form';
// import { useFormContext, Controller, } from 'react-hook-form';
// import { Radio, RadioGroup, FormHelperText, FormControlLabel, TextField } from '@mui/material';

// RHFRadioGroupCommentBox.propTypes = {
//     name: PropTypes.string,
//     options: PropTypes.array,
// };

// export default function RHFRadioGroupCommentBox({ name, options, ...other }) {
//     const { control, setValue, watch } = useFormContext(); // Adding setValue and watch from react-hook-form

//     const selectedOption = watch(name); // Get the selected option value

//     const handleOptionChange = (optionValue) => {
//         setValue(name, optionValue); // Set the selected option value
//     };
//     const textboxLabel = name+" Comment";
//     const textBoxName = name+"Comment"


//     return (
//         <Controller
//             name={name}
//             control={control}
//             render={({ field, fieldState: { error } }) => (
//                 <div>
//                     <RadioGroup {...field} row {...other}>
//                         {options.map(option => (
//                             <FormControlLabel
//                                 key={option.value}
//                                 value={option.value}
//                                 control={<Radio />}
//                                 label={option.label}
//                                 onChange={() => handleOptionChange(option.value)} // Handle option change
//                             />
//                         ))}
//                     </RadioGroup>

//                     {!!selectedOption && selectedOption === 'yes' && ( // Change 'yourDesiredOptionValue' to the value that should trigger the text box
//                         <RHFTextField name = {textBoxName} label = {textboxLabel}/>
//                         // <TextField
//                         //     label= {textboxLabel}
//                         //     variant="outlined"
//                         //     fullWidth
//                         //     margin="normal"
//                         //     name = {textBoxName}
//                         //     // You might need to hook this value into your form state as needed
//                         //     // For example, using setValue from react-hook-form
//                         //     //value={additionalInformation}
//                         //     // onChange={(e) => setAdditionalInformation(e.target.value)}
//                         // />
//                     )}

//                     {!!error && (
//                         <FormHelperText error sx={{ px: 2 }}>
//                             {error.message}
//                         </FormHelperText>
//                     )}
//                 </div>
//             )}
//         />
//     );
// }

import PropTypes from 'prop-types';
import { RHFTextField } from '../hook-form';
import { useFormContext, Controller } from 'react-hook-form';
import { Radio, RadioGroup, FormHelperText, FormControlLabel } from '@mui/material';

RHFRadioGroupCommentBox.propTypes = {
    name: PropTypes.string,
    options: PropTypes.array,
    defaultValue: PropTypes.string, // Update the PropTypes to reflect the change
};

export default function RHFRadioGroupCommentBox({ name, options, defaultValue = null, ...other }) {
    const { control, setValue, watch } = useFormContext();

    const selectedOption = watch(name);

    const handleOptionChange = (optionValue) => {
        setValue(name, optionValue);
    };

    const textboxLabel = `${name} Comment`;
    const textBoxName = `${name}Comment`;

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue} // Set default value
            render={({ field, fieldState: { error } }) => (
                <div>
                    <RadioGroup {...field} row {...other}>
                        {options.map(option => (
                            <FormControlLabel
                                key={option.value}
                                value={option.value}
                                control={<Radio />}
                                label={option.label}
                                onChange={() => handleOptionChange(option.value)}
                            />
                        ))}
                    </RadioGroup>

                    {!!selectedOption && selectedOption === 'yes' && (
                        <RHFTextField name={textBoxName} label={textboxLabel} />
                    )}

                    {!!error && (
                        <FormHelperText error sx={{ px: 2 }}>
                            {error.message}
                        </FormHelperText>
                    )}
                </div>
            )}
        />
    );
}



