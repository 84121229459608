import { useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';

import { Box, Button, AppBar, Toolbar, Container, Grid, Typography } from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
// utils
import cssStyles from '../../utils/cssStyles';
// config
import { HEADER } from '../../config';
import { PATH_AUTH } from '../../routes/paths';

// components
import Image from '../../components/Image';
import Logo from '../../components/Logo';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';

// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    height: HEADER.MOBILE_HEIGHT,
    paddingTop: theme.spacing(4),
    // paddingRight: theme.spacing(20),
    paddingBottom: theme.spacing(4),
    transition: theme.transitions.create(['height', 'background-color'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up('md')]: {
        height: HEADER.MAIN_DESKTOP_HEIGHT,
    },
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
    left: 0,
    right: 0,
    bottom: 0,
    height: 24,
    zIndex: -1,
    margin: 'auto',
    borderRadius: '50%',
    position: 'absolute',
    width: `calc(100% - 48px)`,
    boxShadow: theme.customShadows.z8,
}));

// ----------------------------------------------------------------------

export default function MainHeader() {
    const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);

    const theme = useTheme();

    const { pathname } = useLocation();

    const isDesktop = useResponsive('up', 'md');

    const isHome = pathname === '/';

    return (
        <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
            <ToolbarStyle
                disableGutters
                sx={{
                    ...(isOffset && {
                        ...cssStyles(theme).bgBlur(),
                        height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
                    }),
                }}
            >
                <Container
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Logo sx={{ width: `${isDesktop ? 180 : 60}`, height: 80 }} />

                    <Box sx={{ flexGrow: 1 }} />

                    {isDesktop && <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />}

                    <Button
                        variant="contained"
                        rel="noopener"
                        href="mailto:info@conciergerpm.com"
                        sx={{
                            bgcolor: 'primary.main',
                            '&:hover': {
                                bgcolor: 'success.main',
                            },
                            mr: 2,
                        }}
                    >
                        <Typography variant="overline">Request Info</Typography>
                    </Button>

                    <Button
                        variant="contained"
                        rel="noopener"
                        href={PATH_AUTH.login}
                        sx={{
                            bgcolor: 'primary.main',
                            '&:hover': {
                                bgcolor: 'success.main',
                            },
                        }}
                    >
                        <Typography variant="overline">Sign In</Typography>
                    </Button>

                    {!isDesktop && <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />}
                </Container>
            </ToolbarStyle>

            {isOffset && <ToolbarShadowStyle />}
        </AppBar>
    );
}
