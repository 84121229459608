import { API } from 'aws-amplify';

const endpoint = {
    createTenant: () => '/tenant',
    getTenant: id => `/tenant/${id}`,
    getTenants: () => '/tenant/list',
    getOrgStructure: id => `/tenant/${id}/orgStructure`,
    getTenantByEmail: id => '/tenant/email',
    getTenantUsers: id => `/tenant/${id}/user/list`,
    getTenantPatients: id => `/tenant/${id}/patient/list`,
    getTenantDevices: id => `/tenant/${id}/device/list`,
    getTenantAssignedDevices: id => `/tenant/${id}/device/assignedList`,
    getNotesTenant: id => `/tenant/${id}/notes`,
    getOnboardingReport: id => `/tenant/${id}/onboardingReport`,
    getCcmLastCallReport:id => `/tenant/${id}/ccmLastCallReport`,
    updateTenant: id => `/tenant/${id}`,
    exportDataPdf: id => `/tenant/${id}/exportDataPdf`,
    exportCcmNote: id => `/patient/${id}/exportCcmNote`,
    exportAllSelectedPdf: id => `/tenant/${id}/exportAllSelectedPdf`,
    createUser: () => '/user',
    getUserTasks: (id) => `/user/${id}/tasks`,
    getUser: (id, role) => `/user/${id}/${role}`,
    getUserTasksNextPage: () => `/user/tasks/nextPage`,
    getUserByEmail: () => '/user/email',
    updateUser: id => `/user/${id}`,
    deleteUser: id => `/user/${id}`,
    deleteUserTask: id => `/user/${id}/task`,
    // zoomAuthorize: () => '/user/zoomAuthorize',
    createPatient: () => '/patient',
    getPatient: id => `/patient/${id}`,
    getPatientByName: () => `/patient/byName`,
    updatePatient: id => `/patient/${id}`,
    deletePatient: id => `/patient/${id}`,
    deletePatients: () => `/patient/deleteAll`,
    assignPatientDeviceSN: id => `/patient/assign`,
    releasePatientDeviceSN: () => `/patient/release`,
    getPatientsNextPage: () => '/patient/nextPage',
    createNote: id => `/patient/${id}/note`,
    createCcmNote: id => `/patient/${id}/ccmnote`,
    createReadings: id => `/patient/${id}/readings`,
    getNotesPatient: id => `/patient/${id}/notes`,
    getCcmNote: id => `/patient/${id}/ccmNoteByID`,
    updateCcmNote: id => `/patient/${id}/updateCcmNote`,
    getPatientObservations: id => `/patient/${id}/observations`,
    getPatientMissedUploads: () => `/patient/missedUploads`,
    getPatientCareTeam: id => `/patient/${id}/careteam`,
    createDevice: () => '/device',
    createDeviceSN: id => `/device/${id}`,
    getDevice: id => `/device/${id}`,
    getOrderDevices: () => `/device/getOrderDevices`,
    getDevices: () => '/device/list',
    placeOrder: () => `/device/placeOrder`,
    getDevicesSN: id => `/device/${id}/list`,
    updateDevice: id => `/device/${id}`,
    updateDeviceSN: id => `/device/${id}/serialNumber`,
    deleteDeviceSN: id => `/device/${id}/serialNumber`,
    updateDeviceUPSCode: id => `/device/${id}/upsCode`,
    getObservationDay: () => '/observation',
    // getObservationMissed: () => '/observation/missed',
    getNextPageObservations: () => '/observation/nextPage',
    getInsuranceProviders: () => '/insurance/list',
    deleteNote :()=> '/note',
    updateNotes: () => '/updatenotes'
};

/**************************
 * Tenant API
 **************************/
export const createTenant = async params => {
    const response = await API.post('tenant', endpoint.createTenant(), {
        body: params,
    });
    return response;
};

export const getTenant = async id => {
    const response = await API.get('tenant', endpoint.getTenant(id));
    return response;
};

export const getTenants = async () => {
    const response = await API.get('tenant', endpoint.getTenants());
    return response;
};

export const getTenantByEmail = async params => {
    const response = await API.get('tenant', endpoint.getTenantByEmail(), {
        queryStringParameters: params,
    });
    return response;
};

export const getOrgStructure = async id => {
    const response = await API.get('tenant', endpoint.getOrgStructure(id));
    return response;
};

export const getTenantUsers = async (id, params) => {
    const response = await API.get('tenant', endpoint.getTenantUsers(id), {
        queryStringParameters: params,
    });
    return response;
};

export const getTenantPatients = async (id, params) => {
    const response = await API.get('tenant', endpoint.getTenantPatients(id), {
        queryStringParameters: params,
    });
    return response;
};

export const getTenantDevices = async id => {
    const response = await API.get('tenant', endpoint.getTenantDevices(id));
    return response;
};

export const getTenantAssignedDevices = async id => {
    const response = await API.get('tenant', endpoint.getTenantAssignedDevices(id));
    return response;
};

export const updateTenant = async (id, params) => {
    const response = await API.put('tenant', endpoint.updateTenant(id), {
        body: params,
    });
    return response;
};

export const getNotesTenant = async (id, params) => {
    const response = await API.get('tenant', endpoint.getNotesTenant(id), {
        queryStringParameters: params,
    });
    return response;
};

export const getOnboardingReport = async (id, params) => {
    const response = await API.get('tenant', endpoint.getOnboardingReport(id));
    return response;
};

export const getCcmLastCallReport = async (id) => {
    const response = await API.get('tenant', endpoint.getCcmLastCallReport(id));
    return response;
};

export const exportDataPdf = async (id, params) => {
    const response = await API.get('tenant', endpoint.exportDataPdf(id), {
        queryStringParameters: params,
    });
    return response;
};

export const exportCcmNote = async (id, params) => {
    const response = await API.get('patient', endpoint.exportCcmNote(id), {
        queryStringParameters: params,
    });
    return response;
};

export const exportAllSelectedPdf = async (id, params, body) => {
    const response = await API.post('tenant', endpoint.exportAllSelectedPdf(id), {
        queryStringParameters: params,
        body: body
    });
    return response;
};



/**************************
 * User API
 **************************/

export const createUser = async params => {
    const response = await API.post('user', endpoint.createUser(), {
        body: params,
    });
    return response;
};

// export const zoomAuthorize = async () => {
//     const response = await API.post('user', endpoint.zoomAuthorize());
//     return response;
// };

export const updateUser = async (id, params) => {
    const response = await API.put('user', endpoint.updateUser(id), {
        body: params,
    });
    return response;
};

export const getUser = async (id, role) => {
    const response = await API.get('user', endpoint.getUser(id, role));
    return response;
};

export const getUserTasksNextPage = async (params) => {
    const response = await API.get('user', endpoint.getUserTasksNextPage(), {
        queryStringParameters: params,
    });
    return response;
};

export const getUserByEmail = async params => {
    const response = await API.get('user', endpoint.getUserByEmail(), {
        queryStringParameters: params,
    });
    return response;
};
export const getUserTasks = async (id, params) => {
    const response = await API.get('user', endpoint.getUserTasks(id), {
        queryStringParameters: params,
    });
    return response;
};

export const deleteUser = async (id, params) => {
    const response = await API.del('user', endpoint.deleteUser(id), {
        body: params,
    });
    return response;
};

export const deleteUserTask = async (id) => {
    const response = await API.del('user', endpoint.deleteUserTask(id));
    return response;
};

/**************************
 * Patient API
 **************************/

export const createPatient = async params => {
    const response = await API.post('patient', endpoint.createPatient(), {
        body: params,
    });
    return response;
};

export const updatePatient = async (id, params) => {
    const response = await API.put('patient', endpoint.updatePatient(id), {
        body: params,
    });
    return response;
};

export const getPatient = async id => {
    const response = await API.get('patient', endpoint.getPatient(id));
    return response;
};

export const getPatientObservations = async (id, params) => {
    const response = await API.get('patient', endpoint.getPatientObservations(id), {
        queryStringParameters: params,
    });
    return response;
};

export const getPatientMissedUploads = async (params) => {
    const response = await API.get('patient', endpoint.getPatientMissedUploads(), {
        queryStringParameters: params,
    });
    return response;
};

export const getPatientByName = async (params) => {
    const response = await API.get('patient', endpoint.getPatientByName(), {
        queryStringParameters: params,
    });
    return response;
};

export const deletePatient = async id => {
    const response = await API.del('patient', endpoint.deletePatient(id));
    return response;
};

export const deletePatients = async (params) => {
    const response = await API.del('patient', endpoint.deletePatients(), {
        body: params,
    });
    return response;
};

export const assignPatientDeviceSN = async (params) => {
    const response = await API.post('patient', endpoint.assignPatientDeviceSN(), {
        body: params,
    });
    return response;
};

export const releasePatientDeviceSN = async (params) => {
    const response = await API.post('patient', endpoint.releasePatientDeviceSN(), {
        body: params,
    });
    return response;
};

export const createNote = async (id, params) => {
    const response = await API.post('patient', endpoint.createNote(id), {
        body: params,
    });
    return response;
};
export const createCcmNote = async (id, params) => {
    const response = await API.post('patient', endpoint.createCcmNote(id), {
        body: params,
    });
    return response;
};

export const createReadings = async (id, params) => {
    const response = await API.post('patient', endpoint.createReadings(id), {
        body: params,
    });
    return response;
};


export const getNotesPatient = async (id, params) => {
    const response = await API.get('patient', endpoint.getNotesPatient(id), {
        queryStringParameters: params,
    });
    return response;
};

export const getCcmNote = async (id, params) => {
    const response = await API.get('patient', endpoint.getCcmNote(id), {
        queryStringParameters: params,
    });
    return response;
};


export const updateCcmNote = async (id, params) => {
    const response = await API.put('patient', endpoint.updateCcmNote(id), {
        body: params,
    });
    return response;
};

export const getPatientCareTeam = async id => {
    const careTeam = await API.get('patient', endpoint.getPatientCareTeam(id));
    return careTeam;
};
export const getPatientsNextPage = async (params) => {
    const response = await API.get('patient', endpoint.getPatientsNextPage(), {
        queryStringParameters: params,
    });
    return response;
};



/**************************
 * Device API
 **************************/

export const createDevice = async params => {
    const response = await API.post('device', endpoint.createDevice(), {
        body: params,
    });
    return response;
};
export const createDeviceSN = async (id, params) => {
    const response = await API.post('device', endpoint.createDeviceSN(id), {
        body: params,
    });
    return response;
};

export const getDevice = async id => {
    const response = await API.get('device', endpoint.getDevice(id));
    return response;
};

export const getDevicesbyTenant = async params => {
    const response = await API.get('device', endpoint.getDevicesbyTenant(), {
        queryStringParameters: params,
    });
    return response;
};

export const getDevices = async () => {
    const response = await API.get('device', endpoint.getDevices());
    return response;
};

export const getOrderDevices = async () => {
    const response = await API.get('device', endpoint.getOrderDevices());
    return response;
};

export const placeOrder = async params => {
    const response = await API.post('device', endpoint.placeOrder(), {
        body: params,
    });
    return response;
};

export const getDevicesSN = async (id, params) => {
    const response = await API.get('device', endpoint.getDevicesSN(id), {
        queryStringParameters: params,
    });
    return response;
};

export const updateDevice = async (id, params) => {
    const response = await API.put('device', endpoint.updateDevice(id), {
        body: params,
    });
    return response;
};
export const updateDeviceSN = async (id, params) => {
    const response = await API.put('device', endpoint.updateDeviceSN(id), {
        body: params,
    });
    return response;
};

export const updateDeviceUPSCode = async (id, params) => {
    const response = await API.put('device', endpoint.updateDeviceUPSCode(id), {
        body: params,
    });
    return response;
};

export const deleteDeviceSN = async (id, params) => {
    const response = await API.del('device', endpoint.deleteDeviceSN(id), {
        body: params,
    });
    return response;
};

/**************************
 * Observation API
 **************************/

export const getObservationDay = async (params) => {
    const response = await API.get('observation', endpoint.getObservationDay(), {
        queryStringParameters: params,
    });
    return response;
};

export const getNextPageObservations = async (params) => {
    const response = await API.get('observation', endpoint.getNextPageObservations(), {
        queryStringParameters: params,
    });
    return response;
};

// export const getObservationMissed = async () => {
//     const response = await API.get('observation', endpoint.getObservationMissed());
//     return response;
// };

/**************************
 * Insurance API
 **************************/

export const getInsuranceProviders = async () => {
    const devices = await API.get('insurance', endpoint.getInsuranceProviders());
    return devices;
};

/************************ 
 * Update Delete Notes
*************************/

export const deleteNote = async (params) => {
    const response = await API.del('user', endpoint.deleteNote(), {
        body:params
    });
    return response;

}

export  const updateNotes = async (params ) => {
    const response = await API.put('user', endpoint.updateNotes(),{
        body: params
    });
    console.log(response)
    return response
}