import PropTypes from 'prop-types';
import { RHFTextField } from '../hook-form';
import { useFormContext, Controller } from 'react-hook-form';
import { Radio, RadioGroup, FormHelperText, FormControlLabel } from '@mui/material';

RHFRadioGroupCommentBoxNo.propTypes = {
    name: PropTypes.string,
    options: PropTypes.array,
    defaultValue: PropTypes.string, // Update the PropTypes to reflect the change
};


export default function RHFRadioGroupCommentBoxNo({ name, options, defaultValue = null, ...other }) {
    const { control, setValue, watch } = useFormContext();

    const selectedOption = watch(name);

    const handleOptionChange = (optionValue) => {
        setValue(name, optionValue);
    };

    const textboxLabel = `${name} Comment`;
    const textBoxName = `${name}Comment`;

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue} // Set default value
            render={({ field, fieldState: { error } }) => (
                <div>
                    <RadioGroup {...field} row {...other}>
                        {options.map(option => (
                            <FormControlLabel
                                key={option.value}
                                value={option.value}
                                control={<Radio />}
                                label={option.label}
                                onChange={() => handleOptionChange(option.value)}
                            />
                        ))}
                    </RadioGroup>

                    {!!selectedOption && selectedOption === 'no' && (
                        <RHFTextField name={textBoxName} label={textboxLabel} />
                    )}

                    {!!error && (
                        <FormHelperText error sx={{ px: 2 }}>
                            {error.message}
                        </FormHelperText>
                    )}
                </div>
            )}
        />
    );
}