import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { Switch, FormControlLabel } from '@mui/material';

RHFSwitchWithOnChange.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
};

export default function RHFSwitchWithOnChange({ name, label, ...other }) {
    const { control } = useFormContext();

    return (
        <FormControlLabel
            control={
                <Controller
                    name={name}
                    control={control}
                    render={({ field: { onChange, value, ...field } }) => (
                        <Switch
                            {...field}
                            checked={value}
                            onChange={(e) => {
                                onChange(e.target.checked);
                                if (other.onChange) {
                                    other.onChange(e);
                                }
                            }}
                        />
                    )}
                />
            }
            label={label}
            {...other}
        />
    );
}